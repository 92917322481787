import { isMobile } from '../utils/index'
export default {
  address: '新北市三重區正義北路153號1樓',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14456.109875522476!2d121.4966295!3d25.0670581!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a95435ff72c9%3A0x4fc17bf77bf2f281!2z5qyj5oeL5qW157a75o6l5b6F5Lit5b-D!5e0!3m2!1szh-TW!2stw!4v1678245848964!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/9MZJfAE6x8WiFwPeA',
  phone: '02-2983-9777',
  fbLink: 'https://www.facebook.com/101707636076747',
  fbMessage: 'https://m.me/101707636076747',
  caseName: '欣懋極綻',
  indigatorLength: 10,

  houseInfos: [
  /*
    ['投資興建', '潤旺建設股份有限公司',],
    ['建築設計', '李承洋建築師',],
    ['行銷企劃', '澄果廣告有限公司',],
    ['經紀人', '翁文正（95）北市經證字第00592號',],
  */
  ],

  gtmCode: ['5TWXKCV'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    // subTitle: '若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！
  //  room_type: ['兩房','三房'],//房型
  },
  lixin_logo:true, //false

} 