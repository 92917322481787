<template>
  <div class="section1">
    <!-- 
    <img src="./s1/mo.jpg" class="t0" />
   -->
<div class="img" data-aos="fade">
  <img src="./s1/img1.png" class="img1" />
<div class="img11"></div>
  <img src="./s1/img22.png" class="img2" />
<div class="img22"></div>
<div class="img23"></div>
  <img src="./s1/img3.png" class="img3" v-if="!isMobile"/>
</div>
    <div class="txt">
		<svg class="logo"  width="350" height="150" viewBox="0 0 350 150" xmlns="http://www.w3.org/2000/svg">
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-851.1373" y1="75" x2="613.4149" y2="75">
	<stop  offset="0" style="stop-color:#F2CBA5"/>
	<stop  offset="0.4" style="stop-color:#EFDCBA"/>
	<stop  offset="0.5" style="stop-color:#FFFFFF"/>
	<stop  offset="0.6" style="stop-color:#EFDCBA"/>
	<stop  offset="1" style="stop-color:#F2CBA5"/>
	<animate
      attributeName="x1" from="-1050" to="1050"
      dur="2s" repeatCount="indefinite" />
	<animate
      attributeName="x2" from="0" to="2100"
      dur="2s" repeatCount="indefinite" />
</linearGradient>
<path d="M241.8,105c1.3,3.4,3.3,18.1-3.4,32l-11.9-5.9C234.3,122.8,240.2,112.9,241.8,105z M266.5,45l-35.6,18.3
	l-3.6-14.5L266.5,45z M268.7,81.2l-34.5,18.6L230.2,86L268.7,81.2z M231.9,53.3c9-14.8,15.3-34.5,18.3-50.4l15,6.2
	C252.2,32.4,245.7,41.6,231.9,53.3z M235.5,90.4c7.3-12.2,20-42.6,23.8-55.7l13.2,6.6C252.3,72,248.3,79.9,235.5,90.4z M247.2,104.4
	c7,4.7,12.5,16.1,14.3,25.2l-12.1,3.5C249.7,123.1,250.1,109.3,247.2,104.4z M258.4,99.1c10.5,5.5,17.3,11.6,20.2,18.3l-11.4,5.7
	C267.5,118.6,261.5,101.9,258.4,99.1z M259.3,68.7c7.9,3.5,14.5,13.9,17.9,22.8L266,95.8C265.7,84.9,264.5,76.5,259.3,68.7z
	 M268.2,147.1c12-20.8,17.1-49.1,14.6-73.5l12.4,2C294.3,103.5,281.6,136,268.2,147.1z M288.8,16.2c1.6,11.6,0.8,24.9-3.4,34.2
	l-11.8-7.1C281.1,36.3,286.4,25,288.8,16.2z M339.5,52.4l6.7-3l-3.8,7.4h-59.8l-1.6-4.5L339.5,52.4z M286.4,96.6
	c10.7,36.6,48.4,36.2,58.4,34.7l-2.7,10.7C336.7,142.8,293.1,149.2,286.4,96.6z M288.1,30.2l-1.6-4.5l49.2,0.2l-0.6,4.4H288.1z
	 M298.6,3.2c9.7,3.4,17.5,9.2,23.3,17.3l-11.8,7.8C309.2,20.1,305.1,10,298.6,3.2z M317.4,54.2v77.7h-11.6V54.2H317.4z M336.7,85.7
	l6.7-3l-3.8,7.4h-24.3l-1.6-4.5L336.7,85.7z M324.5,51.2c4.4-7.9,7.2-17.4,8.5-28.5l14.1,5.9C341.4,37.9,331.8,46.5,324.5,51.2z
	 M66.4,114.1c5.5-20.5,9-50.7,8-72.4l11.3,4.2C79.3,93.6,71.2,106.9,66.4,114.1z M110.6,32.3l6.7-3l-3.8,7.4h-45L67,32.2L110.6,32.3
	z M98.3,9.4v131.1l-11.8,6V3.3L98.3,9.4z M97.4,42c9.3,10.6,15.9,18.8,19.9,32l-11.9,6C104.6,64.5,101.9,51.8,97.4,42z M182.6,137.8
	l7.1-3l-3.9,7.4H106l-1.6-4.5L182.6,137.8z M124,46v66.5l-9.4,6V40L124,46z M118.7,51l0-4.5c5.6-4.5,11.9-4.4,17.7,0.2l0,4.4
	C130,46.4,125.1,46.5,118.7,51z M120.9,17.2l-1.6-4.5l51.1,0.2v4.4H120.9z M121.2,108v-4.5l13,0.2v4.4H121.2z M139.4,118.6l-9.1-6
	V46l9.1-6V118.6z M131.1,136.8c16.8-10.9,15.3-26,15.7-45.4V40.8l10.3-6v56.7C157.1,103.8,157.7,127.3,131.1,136.8z M149.3,40.5
	c6.7-10.9,9.9-20.6,13-32.9l15.4,8.7C168.3,27.2,158.9,35.2,149.3,40.5z M153.3,127.9c17.2-29.9,17.8-53,20.8-89.3l11.8,3
	C182.9,75.1,175.6,105,153.3,127.9z M159.1,51.3c10.6,13.1,26.2,48,31,63.8l-12.5,5.4C176.9,109.5,168.6,70,159.1,51.3z M161,46.3
	l-1.7-4.5l20.8,0.2v4.4H161z M31.7,104.7c-1.3,1.1-3.4,2.3-5.4,3.2c0.5,0.6,0.7,1.2,0.7,1.8c0,0.8-0.3,1.4-0.9,1.8h2.4l2-2.3
	l3.7,3.9c-0.3,0.4-0.8,0.5-1.7,0.5c-0.9,1.5-2.5,3.6-3.8,4.7l-0.6-0.3c0.3-1.3,0.6-3.8,0.7-5.5h-2.1V123c0,2.9-0.6,4.7-4.7,5
	c0-2.9-0.7-3.4-2.9-3.7v-0.7h3.1c0.4,0,0.5-0.2,0.5-0.7v-4.4c-1.9,2.3-4.1,4.3-6.8,6l-0.5-0.8c2.9-3.1,5.1-7.1,6.4-11.3h-4.6l-0.3-1
	h7.1l-0.2-0.1c-0.8-1.7-2.5-3.7-4.1-4.8l0.2-0.5c2.4-0.1,4.1,0.3,5.2,0.9c0.7-1.1,1.5-2.4,2.1-3.5h-9.2l-0.3-1h9.4l2.2-2.5l4,4.2
	C33.1,104.6,32.6,104.7,31.7,104.7z M34,134.6c-0.8,2.5-1.4,4.1-1.9,4.7c-0.4,0.6-0.8,0.7-1.9,0.7h-7.9c-1.5,0-1.6,0-1.6-1.1v-6.2
	c1.1-0.1,1.4-0.5,1.4-1l-5.9-0.5v7.6c0,4.1,1,5,5.8,5h8.1c4.8,0,6.2-1,6.2-2.8c0-0.7-0.3-0.9-2-1.5l0-4.9H34z M13.1,135.4
	c0-1-0.1-2.2-0.5-3.5H12c-0.2,2.9-2.7,4.8-4.8,5.4c-1.1,0.5-2.1,1.5-2.1,2.7c0,1.4,1.2,2.3,2.7,2.3C10.2,142.3,13.1,140,13.1,135.4z
	 M5.2,126.3c1.9-2,3.5-4.3,4.9-6.7v10.1c3.4,0,4-1.3,4-1.4v-14c0.9,1.2,1.6,3,1.7,4.4c0.5,0.6,1,0.8,1.5,0.8c1,0,2-1,2-2.2
	c0-1.4-1.3-3.1-5.2-3.9v-3.8h3.6c1.1,0,1.7-0.2,2-0.9l-3.4-3l-1.8,2.9h-0.3v-7.5c1.2-0.1,1.6-0.5,1.7-1.1l-5.7-0.5v9.1H4.9l0.3,1h4
	c-0.7,5.7-2.1,11.4-4.5,15.9L5.2,126.3z M20.3,128.3l-0.2,0.5c2.3,1.4,4.7,4.1,5.8,6.5c0.5,0.4,1,0.5,1.5,0.5c1.3,0,2.2-1.2,2.2-2.6
	C29.6,131.1,27.4,128.2,20.3,128.3z M40.6,109.8H45c1.1,0,1.7-0.2,2-0.9l-3.6-3.1l-1.9,3h-1.4v-7.4c1.3-0.2,1.7-0.5,1.8-1.2L36,99.7
	v9.1h-4.3l0.3,1h3.4c-1.2,5.7-3.3,11.2-6.6,15.4l0.5,0.7c2.8-2.1,5.1-4.6,6.9-7.5v11.2c3.4,0,4.1-1.3,4.1-1.4v-15.7h0
	c0.6,5.8,1.6,10.3,3.6,13.5c0.6-2.5,1.7-3.9,3.3-4.3l0.1-0.5C44.1,119,41.8,114.7,40.6,109.8z M35.3,131.3l-0.3,0.4
	c2.9,2.2,5.7,6.2,6.5,9.7c0.6,0.5,1.2,0.8,1.8,0.8c1.4,0,2.5-1.4,2.5-3.2C45.9,136.3,43.3,132.5,35.3,131.3z M17.1,90.3h0.8
	c2.3,0,3.7-1.4,3.7-1.8V63.4h3.2c1,0,1.6-0.1,1.9-0.6h0c-0.6,2.2-1.3,4.3-2,5.8l0.6,0.4c2.7-2.9,4.7-6.8,6.3-11.6h0.6
	c-0.1,12-0.2,23.3-9.4,32.2l0.5,0.8c9.5-6.2,12.1-14.5,13-23.9h0c0.7,10.3,2.3,19.2,6.5,24c0.4-2.9,1.7-4.6,3.8-5.1l0.1-0.6
	c-7-5.2-9.3-14.1-10-26.6v-0.8h3.8c-0.2,3.2-0.6,8.1-1.1,10.7l0.5,0.3c1.8-2.4,3.8-6.7,5-9.6c0.9-0.1,1.4-0.2,1.7-0.6L42.5,54
	l-2.4,2.6H32c0.8-2.6,1.5-5.5,2.1-8.5c1.1-0.1,1.5-0.5,1.6-1.1L29,45.6c-0.3,5.9-1.1,12-2.3,16.9l-4.3-3.6L20,62.5h-7.7V52.3
	c4.1-0.3,8.6-1.1,12.6-2c0.4,0.1,0.7,0.2,1,0.2c0.3,0,0.5-0.1,0.7-0.3l-5-4.5c-2.8,2-6.1,3.9-9.3,5.3l-4.4-1.6v16.3
	c0,8.2,0,17.1-3,24.2l0.6,0.5c6.6-6.8,6.8-16.6,6.8-24.5v-2.3h4.8V90.3z M20.7,30.8c4.3,1.3,5,2.7,12.7,2.4
	c7.7-0.3,12.2-2.1,15.3-6.3c-9.2,0.3-10.1-1-11.9-2c-4,3.6-7.9,3.9-16.2,2.6C7.9,25.6,3.2,31.9,3.2,31.9
	C8.4,28.8,16.4,29.5,20.7,30.8z M25.2,15.6c1.2-6.6,3.7-9.5,6.9-12.7C22.7,6.3,16.3,11,11.9,15.6H25.2z M9.4,18.4
	C4.2,25,3,30.6,3,30.6c5.6-7.2,17.4-8.7,21.5-8.1c0.1-1.5,0.2-2.8,0.3-4H9.4z M28.5,19.4h2v1.1c0,0.6,0.2,0.8,0.7,0.8
	c0.4,0,0.6,0,0.8,0c0.2,0,0.4,0,0.8,0c0.5,0,0.7-0.3,0.7-0.8v-4.4h2v4.6c0,1.4-0.7,2.2-2,2.2c-0.8,0-1.3,0-1.5,0c-0.2,0-0.7,0-1.5,0
	c-1.3,0-2-0.7-2-2.2V19.4z M41,16.1l2.1,4.5l2.1-4.5H48v6.8h-2.5v-3.6l-1.4,3.6H42l-1.4-3.5v3.5h-2.5v-6.8H41z"/>
</svg>


<!--
<svg data-aos="zoom-in" data-aos-delay="0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 747 212">
  <g>
	<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="222.671" y1="350.3869" x2="559.3405" y2="-50.8401">
		<stop  offset="0" style="stop-color:#FBCF00"/>
		<stop  offset="0.1" style="stop-color:#FFFFC2"/>
		<stop  offset="0.2" style="stop-color:#EDD05A"/>
		<stop  offset="0.3" style="stop-color:#CB990C"/>
		<stop  offset="0.48" style="stop-color:#EFCA84"/>
		<stop  offset="0.63" style="stop-color:#C78A03"/>
		<stop  offset="0.76" style="stop-color:#FFF2C4"/>
		<stop  offset="0.9" style="stop-color:#FFE07B"/>
		<stop  offset="1" style="stop-color:#F0CC7E"/>
	</linearGradient>
	<path style="fill:url(#SVGID_1_);" d="M458.2,70.6L451,66c3.2-3.8,4.6-8,5-13.3h-3.9v-6.2l-2,2.8c-0.5-0.9-1.2-2-1.7-2.8v23.7h-8V55.1
		c-0.1,0.3-0.3,0.6-0.4,1l-0.7,1.5l-5.1-6.4c2.2-4.3,4.8-12.7,6-18.2h-4.7v-7.8h4.8V15.1h8v10.1h3.6v1.5l2.5-3.2l0.8,0.5
		c1.5-3.1,2.8-6.2,3.9-9.3l7,3.2c-0.9,1.7-1.8,3.7-2.5,5.1l2.9,1.3c-0.2-3.2-0.2-6-0.2-8.9l8.2-0.6c0,3.2,0,6.6,0.1,9.4l0.2,0.1
		c1.3-3.1,2.4-6.3,3.4-9.6l7,3.1c-0.5,1.3-1.1,2.8-1.7,4l6.1,2.7c-0.9,2.1-2,4.3-2.9,6.2l1-0.3c1.5,3.7,2.6,7.5,3.3,11.6l-4.4,1.3
		c0.1,0.1,0.9,0.7,1.1,0.9l-0.9,0.9h3.4v7.5h-6.5l3.7,1.8c-0.7,1.3-1.5,2.7-2.3,3.9l6,2.4c-2.6,10.3-9,11.7-14,5.6
		c-2.2,1.5-5.1,2.9-8,4.1l-4-7.1l0.8-0.3c-0.9-0.7-1.9-1.4-3-2.1C461.9,64.7,460.3,67.9,458.2,70.6L458.2,70.6z M464.1,53.4
		c3.4,1.7,5.3,2.8,7.7,4.3l-3.1,4.3c1.7-0.7,3.1-1.6,4.3-2.4c-0.9-2.1-1.7-4.4-2.4-6.8H464C464.1,53,464.1,53.2,464.1,53.4
		L464.1,53.4z M478.7,52.8c0.1,0.2,0.1,0.4,0.2,0.6c0.2-0.2,0.3-0.4,0.4-0.6H478.7L478.7,52.8z M453.1,45.3h15.8
		c-0.1-0.5-0.2-1-0.3-1.5l-4,1.2c-0.1-0.3-0.2-1.1-0.3-1.4c-3.1,1-6.6,1.5-10.4,1.6L453.1,45.3L453.1,45.3z M476.5,43.8
		c0.1,0.4,0.1,0.7,0.2,1.1l0.9-1.1C477.3,43.8,476.9,43.8,476.5,43.8z M448.4,34.8c1.2,1.1,2.3,2.2,3.3,3.3v-0.2c1,0,2.2,0,3.2,0
		c0.9-1.5,1.7-2.8,2.5-4.1c-1.7-1.2-3.4-2.3-5.3-3.4V33h-3.6L448.4,34.8L448.4,34.8z M475.2,32.7c0.1,1.4,0.2,2.7,0.4,4.1
		c0.1,0,0.3,0,0.5,0c0.5-0.9,0.9-1.7,1.3-2.5C476.6,33.7,475.9,33.2,475.2,32.7z M467,30.9c-0.3,0.5-0.6,1-0.8,1.5l0.9-0.3
		C467.1,31.7,467,31.3,467,30.9z M354.7,44.8v7.9c0,5.4-1.1,9.4-3.2,12.1c-2.1,2.9-6.3,4.9-11,5.7h0.1l-3.7-7.7
		c2.5-0.4,5.5-1.1,6.9-2.1h-6V46.1h-0.2l-0.9-2.6v26.9h-7.9V61c-3.5,0.9-6.9,1.6-10.4,2.1l-1.6-8.5c0.8,0,1.7-0.1,2.5-0.2V24.5H317
		v-7.9h22.2v7c1.5-2.8,2.8-5.9,4-9l6.9,3.1c-0.4,0.9-0.9,2-1.3,2.9l6.4,2.7l-0.2,0.4l0.6,0.4c1.6-3.5,2.8-6.3,3.8-9.5l6.9,3.1
		c-0.4,0.9-0.8,2-1.2,2.9l6.3,2.6c-1.3,2.9-2.7,5.9-3.9,8.5l2.6-0.6c1.2,4.2,2.1,8.3,2.5,12.5l-6.1,1.5h4.6v17.2h-7.5V59h-1.2v11.3
		h-7.7L354.7,44.8L354.7,44.8z M345.4,59v0.2c0,0,0.1-0.2,0.2-0.2H345.4z M362.6,51.5h1.2V45h2.3c-0.1-0.6-0.2-1.4-0.3-2
		c-1.9,0.4-3.8,0.8-5.9,1.1h2.7L362.6,51.5L362.6,51.5z M345.4,51.5h1.7v-7.6h1.6c0-0.2-0.1-0.5-0.1-0.7c-2,0.7-4.1,1.3-6.3,1.8h3.1
		L345.4,51.5L345.4,51.5z M353.8,43.9h0.8v-0.2L353.8,43.9z M336.8,30.8v7.6c0.6-0.1,1.2-0.2,1.7-0.3c0.8-1.3,1.7-2.6,2.5-4.1
		L336.8,30.8z M353.3,32c0.6,1.7,1.2,3.5,1.6,5.2c0.6-0.1,1.1-0.1,1.7-0.2c0.6-1,1.1-1.9,1.6-2.9c-2.1-1.3-4.2-2.6-6.3-4
		c-0.5,0.9-0.9,1.7-1.4,2.5L353.3,32L353.3,32z M336.8,24.4v0.3l0.3-0.3H336.8z M537,70.3c-1.2,0-3-0.2-4.5-0.3l-1.7-8h-8.3v8.3
		h-8.2V62h-3.7v-7.3h3.7V44.4l-4.1,5.8c-0.6-1-1.4-2.2-1.9-3.2v23.4h-8.2V53.6c-0.6,1.3-1.2,2.7-1.8,4L493,51
		c2.4-4.2,5.3-12.4,6.7-18.2h-5.3v-8.2h5.8v-9.4h8.2v9.4h4v-6.9h7.4v-2.5h8.4v2.5h4v-2.5h8.4v2.5h6.7v7.5h-0.9v7.2h1.8v7.5h-1.6
		v14.8h2.9V62h-2.9C546.6,65.8,544.7,70.4,537,70.3z M535.5,62c0.8,0.1,1.6,0.2,2.1,0H535.5z M513.5,39.9c0.3,0.4,0.7,0.8,1,1.1
		v-1.1H513.5z M508.3,34.5c1.4,1.4,2.7,2.7,3.8,3.9v-5.7h-3.8V34.5z M513.4,32.4h0.6v-7.2h-0.6V32.4z M404.3,69.6
		c-6.6,0-14.5,0.9-14.5-7.4v-9.8h-6.9v-7.9c-1,1.8-1.9,2.8-3,4.2l-4.7-6.2c2.3-2.2,4.9-8.1,6.2-12.1h-4.1v-7.8h5.5v-7.4h7.7v7.4h3.1
		v-6.4h19.7v6c-0.1,0.1-0.3,0.3-0.4,0.5h3v-7.4h7.7v7.4h6.2v7.8h-3.6c1.2,3.9,2.9,7.5,5.6,10.1l-4.2,8.2c-1.6-1.8-2.8-3.4-4-5.8v8
		h0.1c2.7,2.9,5.3,6.5,7.9,10.4l-7.4,5.9c-2.2-3.9-4.8-7.7-7.8-11.4l5.7-3.9H416v-8.9c-1,1.8-2.1,3.5-3.3,5.1l-5.6-5.9
		c0,2.8-0.4,4.8-1.2,5.9c2.1,2.2,4.1,4.7,5.9,7.3l-7.1,5.6c-1.7-2.6-3.8-5.4-6.3-8.3l3.6-2.5c-1.7,0.2-3.5,0-4.9-0.7l-0.7-4.5
		c-0.6,0.5-1.3,1.1-1.9,1.7l-4-4v7.9h7.6v9.2c0,1.1,0.6,1.4,4.8,1.3c8.6-0.2,7.9,1.2,8.5-5l8.6,5.1c-1,5.9-2.4,7.2-6.1,7.7
		C411.7,69.4,408.1,69.6,404.3,69.6z M399.1,42.9c0.2,0.1,0.4,0.1,0.6,0.1c0-0.2,0-0.4,0.1-0.8C399.5,42.5,399.3,42.7,399.1,42.9z
		 M407.2,38v4c0.7-0.9,1.4-1.8,2-2.8L407.2,38L407.2,38z M395.4,33.9c0.1,0.1,0.7,0.7,0.9,0.8l-1.9,2.3c1.1-1,2-2.1,2.8-3.1H395.4z
		 M391,30.3c0.8,0.5,1.5,1.1,2.3,1.7v-1.7H391z M408.2,26.9h2.5v-2.1C409.9,25.6,409.1,26.3,408.2,26.9z M394.3,26.9h2.5
		c-0.5-0.5-1.4-1.1-1.9-1.6l3.2-2.2h-3.7v3.8H394.3z M385,68.2l-7.9-4.2c1.9-3.8,3.4-7.8,4.2-11.6l8.4,2.7
		C388.4,60,386.9,64.4,385,68.2L385,68.2z M534.4,54.7h4v-0.3h-4V54.7z M522.6,54.7h3.5v-0.3h-3.5V54.7z M327.4,48.4v4.8
		c0.5-0.1,1-0.2,1.5-0.3v-4.6L327.4,48.4L327.4,48.4z M534.4,47.3h4V47h-4V47.3z M522.6,47.3h3.5V47h-3.5V47.3z M327.4,40.7h1.5
		v-4.3h-1.5V40.7z M327.4,28.7h1.5v-4.3h-1.5V28.7z M567.2,106.1c-2.8,6.8-5.9,13.4-8.9,19h6.6v-13.3h24.6v-0.6h-18.1v-15h18.1v-5.6
		h15.7v5.6h22.4v15.6h6.9v14.9h-6.9V142h2.6v15h-25v0.2h28v14.9h-28v4.6c9.3-0.1,20.1-0.7,30.5-1.5l-5.6,17.2
		c-34.5,0-60.9,1.7-76.8-10c-3.3,4.4-7.5,8.8-11.9,13l-10.9-11.9c4.9-3.9,9.6-8.5,12.7-12.6c-3.4-5.3-6.1-12.5-7.8-19.9l12.3-5.3
		c1.1,3.2,2.4,6.8,3.7,9.5c1.4-4.3,2.4-9.2,3.1-14.7H549c-1.3,1.9-2.7,3.6-4.1,5.4l-12-8c6-8.5,11.2-17.7,15.5-27.6h-14.7V94.4h33.8
		v11.7H567.2z M565.5,171.8c4.9,2.2,12.8,3.7,23.8,4.3v-4.3H565.5z M561.1,169.1c0.7,0.6,1.5,1.2,2.3,1.5c0,0,0.1,0,0.1,0.1v-6.9
		C562.7,165.7,561.9,167.3,561.1,169.1L561.1,169.1z M565.8,157h23.6v-0.2h-20.7v-12C567.8,149.3,566.8,153.3,565.8,157z
		 M569.1,141.9h1.7v-12.1C570.3,134,569.8,138.1,569.1,141.9z M571.1,127.2h18.3v-0.6h-18.2L571.1,127.2L571.1,127.2L571.1,127.2z
		 M365.5,194.9l-10.4-13.8c14.9-16.1,20.2-33,22.6-57.9H377c-2.9,6.6-5.5,11.2-8.8,15.9l-5.4-4.1v4.5h-8.1v54h-15.8v-54h-5.5
		c-0.7,24-1.2,37.4-11.5,54l-12.3-10c9.3-14.7,8.7-35.9,8.7-84.9c15.9-1.5,29.7-4.1,40.9-8l7.7,13.4c1.8-4.7,3.1-9.2,4.4-13.9
		l15.7,4.6c-1.2,4.4-2.4,8.8-3.9,13.2h32.5c-1.7,11.8-5.4,23.5-10.7,34.6l-12.2-7.3c3.4,17.8,8.9,34.3,22.7,46.5l-13.1,12.6
		c-8.4-9.4-13.3-18.8-16.8-30.4C380.6,175.8,374,186.1,365.5,194.9z M393.7,123.2c-0.4,3.7-0.7,7.5-1.2,11.1c1.6-3.5,3-7.1,4.1-11.1
		C396.6,123.2,393.7,123.2,393.7,123.2z M333.4,123.8h24.2c3.3-5.3,6.1-11.9,8.5-18.2c-9.7,3-20.4,5.1-32.7,6.7V123.8L333.4,123.8z
		 M737.9,194.3c-9-3-17.4-7.3-24.3-12.6c-7.2,5.6-15.9,9.9-25.3,12.6l-4-7.9v3.6H661v4h-15.4v-41.2h38.6v25.5
		c6.6-1.9,12.5-4.5,17.5-7.8c-4.8-5.6-8.8-12-11.9-19.2h-5.5v-15.7h5.7l-6.7-5.9v7.9h-0.4v15.1h-36.4v-15.4h0.5v-14.8h-5.2v-15.7
		h5.6V92.4h35.5v14.2h5.2v14.3c2-2.7,2.7-5.9,2.8-9.9V91.9h39.8c0,18.3-0.4,27.6,0.5,27.6c0.7,0,0.6-1.3,1.1-11.5l14.8,7.5
		c-0.7,15.4-4,18.5-15.5,18.5c-13.4,0-17.5-2.3-17.5-14.4c0-3,0-11.9,0-11.9h-7.5c-0.1,13.1-1.4,20.1-9,27.8h40.3l-0.1,11.6
		c-3.5,9.3-7.6,17-12.2,22.9c5.6,3.4,13,6.3,20.5,8.4l-8.3,16L737.9,194.3z M683.3,122.3v3.1c1.3-1,2.5-2,3.5-3.1H683.3z
		 M461.2,192.1c-11.9-0.5-14.4-7.6-14.4-13.4v-18.5H434v-14.8c-1.9,3.3-3.5,5.3-5.6,7.8l-8.7-11.7c4.2-4.2,9.2-15.3,11.6-22.7h-7.7
		v-14.5H434V90.6h14.4v13.8h5.8v-12H491v11.2c-0.2,0.3-0.5,0.6-0.8,0.9h5.7v-14h14.5v13.8H522v14.5h-6.7c2.3,7.2,5.5,14,10.5,18.9
		L518,153c-2.9-3.3-5.2-6.4-7.6-10.9v15.6l0.1-0.1c5,5.5,9.9,12.2,14.7,19.6l-13.8,11c-4.1-7.2-9-14.3-14.6-21.3l10.6-7.2h-11.5
		v-16.6c-1.9,3.3-3.9,6.4-6.1,9.5l-10.5-11.1c0,5.3-0.8,8.9-2.2,11.2c3.8,4.1,7.6,8.8,11.1,13.7l-13.3,10.5c-3.1-4.9-7-10-11.8-15.5
		l6.8-4.8c-3.1,0.4-6.6,0-9.1-1.3l-1.4-8.4c-1.1,1-2.4,2.1-3.6,3.1l-7.5-7.6v14.9h14.2v17.3c0,1.7,0.9,2.2,4,2.4
		c3.5,0.2,9,0.2,17.5-0.2c1.7-0.1,2.3-0.7,2.5-1.7c0.6-2.7,1-7.4,1-7.4l16,9.5c-1.8,9.8-3.3,13.8-12.6,14.5
		C480.2,192.6,472.7,192.6,461.2,192.1z M464.2,142.5c0.4,0.1,0.7,0.2,1.1,0.2c0-0.3,0-0.8,0.1-1.4
		C464.9,141.6,464.5,142.1,464.2,142.5L464.2,142.5z M479.4,133.2v7.6c1.3-1.7,2.6-3.3,3.8-5.2L479.4,133.2L479.4,133.2z
		 M457.4,125.7c0.2,0.2,1.3,1.3,1.6,1.5l-3.5,4.4c2-1.9,3.8-3.9,5.4-5.9L457.4,125.7L457.4,125.7z M449,118.8c1.5,1,2.9,2.1,4.3,3.2
		v-3.2H449z M481.2,112.6h4.8v-4C484.5,110,482.8,111.3,481.2,112.6z M455.3,112.6h4.6c-1-0.9-2.6-2.2-3.6-3l6-4.1h-7V112.6
		L455.3,112.6z M437.9,189.8L423,182c3.6-7.2,6.3-14.5,8-21.7l15.7,5C444.3,174.5,441.4,182.7,437.9,189.8L437.9,189.8z M661,175
		h7.9v-7.6H661V175z M707,151.1c1.8,3.3,3.9,6.2,6.4,8.8c2.2-2.6,4.1-5.5,5.8-8.8H707z M605,127.1h6.7v-0.6H605V127.1z M605,111.7
		h6.7v-0.6H605V111.7z M12.1,196.7c54.6-63.3,138.8,12.8,208.3-41.1l69.1,16.4C190.7,267.6,94.6,153.9,12.1,196.7L12.1,196.7z
		 M149,67c7.9-17.1,30.2-67,30.2-67C125.8,20.7,88.7,51.2,60.3,86c0,0,46.3,0,61.7,0C139.6,86,141.7,82.9,149,67z M40.6,112.7
		c-15.6,23.4-28.5,48-40.5,72.5l4.7,4.7c40.3-47.6,83.4-59.9,135.8-55.7V102c0,0-60.8,0-81.1,0C48,102,47.4,102.6,40.6,112.7z
		 M200.8,110.3c-0.1,4.1-0.4,7.2-4.3,7.3c-3.9,0.1-4.2-3.4-4.3-7.1l-12.8,1.7c0.8,6.1,2.1,15.4,18,15.4c19.1,0,16.7-18.6,16.7-20.5
		V83.6h-13.4L200.8,110.3L200.8,110.3z M223,126.9h10.9v-33l8.4,33h9.9l8.4-33v33h10.9V83.6H254l-6.7,26.3l-6.8-26.3h-17.6
		L223,126.9L223,126.9z"/>
	<g>
		<defs>
			<path id="SVGID_2" d="M458.2,70.6L451,66c3.2-3.8,4.6-8,5-13.3h-3.9v-6.2
				l-2,2.8c-0.5-0.9-1.2-2-1.7-2.8v23.7h-8V55.1c-0.1,0.3-0.3,0.6-0.4,1l-0.7,1.5l-5.1-6.4c2.2-4.3,4.8-12.7,6-18.2h-4.7v-7.8h4.8
				V15.1h8v10.1h3.6v1.5l2.5-3.2l0.8,0.5c1.5-3.1,2.8-6.2,3.9-9.3l7,3.2c-0.9,1.7-1.8,3.7-2.5,5.1l2.9,1.3c-0.2-3.2-0.2-6-0.2-8.9
				l8.2-0.6c0,3.2,0,6.6,0.1,9.4l0.2,0.1c1.3-3.1,2.4-6.3,3.4-9.6l7,3.1c-0.5,1.3-1.1,2.8-1.7,4l6.1,2.7c-0.9,2.1-2,4.3-2.9,6.2
				l1-0.3c1.5,3.7,2.6,7.5,3.3,11.6l-4.4,1.3c0.1,0.1,0.9,0.7,1.1,0.9l-0.9,0.9h3.4v7.5h-6.5l3.7,1.8c-0.7,1.3-1.5,2.7-2.3,3.9
				l6,2.4c-2.6,10.3-9,11.7-14,5.6c-2.2,1.5-5.1,2.9-8,4.1l-4-7.1l0.8-0.3c-0.9-0.7-1.9-1.4-3-2.1C461.9,64.7,460.3,67.9,458.2,70.6
				L458.2,70.6z M464.1,53.4c3.4,1.7,5.3,2.8,7.7,4.3l-3.1,4.3c1.7-0.7,3.1-1.6,4.3-2.4c-0.9-2.1-1.7-4.4-2.4-6.8H464
				C464.1,53,464.1,53.2,464.1,53.4L464.1,53.4z M478.7,52.8c0.1,0.2,0.1,0.4,0.2,0.6c0.2-0.2,0.3-0.4,0.4-0.6H478.7L478.7,52.8z
				 M453.1,45.3h15.8c-0.1-0.5-0.2-1-0.3-1.5l-4,1.2c-0.1-0.3-0.2-1.1-0.3-1.4c-3.1,1-6.6,1.5-10.4,1.6L453.1,45.3L453.1,45.3z
				 M476.5,43.8c0.1,0.4,0.1,0.7,0.2,1.1l0.9-1.1C477.3,43.8,476.9,43.8,476.5,43.8z M448.4,34.8c1.2,1.1,2.3,2.2,3.3,3.3v-0.2
				c1,0,2.2,0,3.2,0c0.9-1.5,1.7-2.8,2.5-4.1c-1.7-1.2-3.4-2.3-5.3-3.4V33h-3.6L448.4,34.8L448.4,34.8z M475.2,32.7
				c0.1,1.4,0.2,2.7,0.4,4.1c0.1,0,0.3,0,0.5,0c0.5-0.9,0.9-1.7,1.3-2.5C476.6,33.7,475.9,33.2,475.2,32.7z M467,30.9
				c-0.3,0.5-0.6,1-0.8,1.5l0.9-0.3C467.1,31.7,467,31.3,467,30.9z M354.7,44.8v7.9c0,5.4-1.1,9.4-3.2,12.1c-2.1,2.9-6.3,4.9-11,5.7
				h0.1l-3.7-7.7c2.5-0.4,5.5-1.1,6.9-2.1h-6V46.1h-0.2l-0.9-2.6v26.9h-7.9V61c-3.5,0.9-6.9,1.6-10.4,2.1l-1.6-8.5
				c0.8,0,1.7-0.1,2.5-0.2V24.5H317v-7.9h22.2v7c1.5-2.8,2.8-5.9,4-9l6.9,3.1c-0.4,0.9-0.9,2-1.3,2.9l6.4,2.7l-0.2,0.4l0.6,0.4
				c1.6-3.5,2.8-6.3,3.8-9.5l6.9,3.1c-0.4,0.9-0.8,2-1.2,2.9l6.3,2.6c-1.3,2.9-2.7,5.9-3.9,8.5l2.6-0.6c1.2,4.2,2.1,8.3,2.5,12.5
				l-6.1,1.5h4.6v17.2h-7.5V59h-1.2v11.3h-7.7L354.7,44.8L354.7,44.8z M345.4,59v0.2c0,0,0.1-0.2,0.2-0.2H345.4z M362.6,51.5h1.2V45
				h2.3c-0.1-0.6-0.2-1.4-0.3-2c-1.9,0.4-3.8,0.8-5.9,1.1h2.7L362.6,51.5L362.6,51.5z M345.4,51.5h1.7v-7.6h1.6
				c0-0.2-0.1-0.5-0.1-0.7c-2,0.7-4.1,1.3-6.3,1.8h3.1L345.4,51.5L345.4,51.5z M353.8,43.9h0.8v-0.2L353.8,43.9z M336.8,30.8v7.6
				c0.6-0.1,1.2-0.2,1.7-0.3c0.8-1.3,1.7-2.6,2.5-4.1L336.8,30.8z M353.3,32c0.6,1.7,1.2,3.5,1.6,5.2c0.6-0.1,1.1-0.1,1.7-0.2
				c0.6-1,1.1-1.9,1.6-2.9c-2.1-1.3-4.2-2.6-6.3-4c-0.5,0.9-0.9,1.7-1.4,2.5L353.3,32L353.3,32z M336.8,24.4v0.3l0.3-0.3H336.8z
				 M537,70.3c-1.2,0-3-0.2-4.5-0.3l-1.7-8h-8.3v8.3h-8.2V62h-3.7v-7.3h3.7V44.4l-4.1,5.8c-0.6-1-1.4-2.2-1.9-3.2v23.4h-8.2V53.6
				c-0.6,1.3-1.2,2.7-1.8,4L493,51c2.4-4.2,5.3-12.4,6.7-18.2h-5.3v-8.2h5.8v-9.4h8.2v9.4h4v-6.9h7.4v-2.5h8.4v2.5h4v-2.5h8.4v2.5
				h6.7v7.5h-0.9v7.2h1.8v7.5h-1.6v14.8h2.9V62h-2.9C546.6,65.8,544.7,70.4,537,70.3z M535.5,62c0.8,0.1,1.6,0.2,2.1,0H535.5z
				 M513.5,39.9c0.3,0.4,0.7,0.8,1,1.1v-1.1H513.5z M508.3,34.5c1.4,1.4,2.7,2.7,3.8,3.9v-5.7h-3.8V34.5z M513.4,32.4h0.6v-7.2h-0.6
				V32.4z M404.3,69.6c-6.6,0-14.5,0.9-14.5-7.4v-9.8h-6.9v-7.9c-1,1.8-1.9,2.8-3,4.2l-4.7-6.2c2.3-2.2,4.9-8.1,6.2-12.1h-4.1v-7.8
				h5.5v-7.4h7.7v7.4h3.1v-6.4h19.7v6c-0.1,0.1-0.3,0.3-0.4,0.5h3v-7.4h7.7v7.4h6.2v7.8h-3.6c1.2,3.9,2.9,7.5,5.6,10.1l-4.2,8.2
				c-1.6-1.8-2.8-3.4-4-5.8v8h0.1c2.7,2.9,5.3,6.5,7.9,10.4l-7.4,5.9c-2.2-3.9-4.8-7.7-7.8-11.4l5.7-3.9H416v-8.9
				c-1,1.8-2.1,3.5-3.3,5.1l-5.6-5.9c0,2.8-0.4,4.8-1.2,5.9c2.1,2.2,4.1,4.7,5.9,7.3l-7.1,5.6c-1.7-2.6-3.8-5.4-6.3-8.3l3.6-2.5
				c-1.7,0.2-3.5,0-4.9-0.7l-0.7-4.5c-0.6,0.5-1.3,1.1-1.9,1.7l-4-4v7.9h7.6v9.2c0,1.1,0.6,1.4,4.8,1.3c8.6-0.2,7.9,1.2,8.5-5
				l8.6,5.1c-1,5.9-2.4,7.2-6.1,7.7C411.7,69.4,408.1,69.6,404.3,69.6z M399.1,42.9c0.2,0.1,0.4,0.1,0.6,0.1c0-0.2,0-0.4,0.1-0.8
				C399.5,42.5,399.3,42.7,399.1,42.9z M407.2,38v4c0.7-0.9,1.4-1.8,2-2.8L407.2,38L407.2,38z M395.4,33.9c0.1,0.1,0.7,0.7,0.9,0.8
				l-1.9,2.3c1.1-1,2-2.1,2.8-3.1H395.4z M391,30.3c0.8,0.5,1.5,1.1,2.3,1.7v-1.7H391z M408.2,26.9h2.5v-2.1
				C409.9,25.6,409.1,26.3,408.2,26.9z M394.3,26.9h2.5c-0.5-0.5-1.4-1.1-1.9-1.6l3.2-2.2h-3.7v3.8H394.3z M385,68.2l-7.9-4.2
				c1.9-3.8,3.4-7.8,4.2-11.6l8.4,2.7C388.4,60,386.9,64.4,385,68.2L385,68.2z M534.4,54.7h4v-0.3h-4V54.7z M522.6,54.7h3.5v-0.3
				h-3.5V54.7z M327.4,48.4v4.8c0.5-0.1,1-0.2,1.5-0.3v-4.6L327.4,48.4L327.4,48.4z M534.4,47.3h4V47h-4V47.3z M522.6,47.3h3.5V47
				h-3.5V47.3z M327.4,40.7h1.5v-4.3h-1.5V40.7z M327.4,28.7h1.5v-4.3h-1.5V28.7z M567.2,106.1c-2.8,6.8-5.9,13.4-8.9,19h6.6v-13.3
				h24.6v-0.6h-18.1v-15h18.1v-5.6h15.7v5.6h22.4v15.6h6.9v14.9h-6.9V142h2.6v15h-25v0.2h28v14.9h-28v4.6c9.3-0.1,20.1-0.7,30.5-1.5
				l-5.6,17.2c-34.5,0-60.9,1.7-76.8-10c-3.3,4.4-7.5,8.8-11.9,13l-10.9-11.9c4.9-3.9,9.6-8.5,12.7-12.6c-3.4-5.3-6.1-12.5-7.8-19.9
				l12.3-5.3c1.1,3.2,2.4,6.8,3.7,9.5c1.4-4.3,2.4-9.2,3.1-14.7H549c-1.3,1.9-2.7,3.6-4.1,5.4l-12-8c6-8.5,11.2-17.7,15.5-27.6
				h-14.7V94.4h33.8v11.7H567.2z M565.5,171.8c4.9,2.2,12.8,3.7,23.8,4.3v-4.3H565.5z M561.1,169.1c0.7,0.6,1.5,1.2,2.3,1.5
				c0,0,0.1,0,0.1,0.1v-6.9C562.7,165.7,561.9,167.3,561.1,169.1L561.1,169.1z M565.8,157h23.6v-0.2h-20.7v-12
				C567.8,149.3,566.8,153.3,565.8,157z M569.1,141.9h1.7v-12.1C570.3,134,569.8,138.1,569.1,141.9z M571.1,127.2h18.3v-0.6h-18.2
				L571.1,127.2L571.1,127.2L571.1,127.2z M365.5,194.9l-10.4-13.8c14.9-16.1,20.2-33,22.6-57.9H377c-2.9,6.6-5.5,11.2-8.8,15.9
				l-5.4-4.1v4.5h-8.1v54h-15.8v-54h-5.5c-0.7,24-1.2,37.4-11.5,54l-12.3-10c9.3-14.7,8.7-35.9,8.7-84.9c15.9-1.5,29.7-4.1,40.9-8
				l7.7,13.4c1.8-4.7,3.1-9.2,4.4-13.9l15.7,4.6c-1.2,4.4-2.4,8.8-3.9,13.2h32.5c-1.7,11.8-5.4,23.5-10.7,34.6l-12.2-7.3
				c3.4,17.8,8.9,34.3,22.7,46.5l-13.1,12.6c-8.4-9.4-13.3-18.8-16.8-30.4C380.6,175.8,374,186.1,365.5,194.9z M393.7,123.2
				c-0.4,3.7-0.7,7.5-1.2,11.1c1.6-3.5,3-7.1,4.1-11.1C396.6,123.2,393.7,123.2,393.7,123.2z M333.4,123.8h24.2
				c3.3-5.3,6.1-11.9,8.5-18.2c-9.7,3-20.4,5.1-32.7,6.7V123.8L333.4,123.8z M737.9,194.3c-9-3-17.4-7.3-24.3-12.6
				c-7.2,5.6-15.9,9.9-25.3,12.6l-4-7.9v3.6H661v4h-15.4v-41.2h38.6v25.5c6.6-1.9,12.5-4.5,17.5-7.8c-4.8-5.6-8.8-12-11.9-19.2h-5.5
				v-15.7h5.7l-6.7-5.9v7.9h-0.4v15.1h-36.4v-15.4h0.5v-14.8h-5.2v-15.7h5.6V92.4h35.5v14.2h5.2v14.3c2-2.7,2.7-5.9,2.8-9.9V91.9
				h39.8c0,18.3-0.4,27.6,0.5,27.6c0.7,0,0.6-1.3,1.1-11.5l14.8,7.5c-0.7,15.4-4,18.5-15.5,18.5c-13.4,0-17.5-2.3-17.5-14.4
				c0-3,0-11.9,0-11.9h-7.5c-0.1,13.1-1.4,20.1-9,27.8h40.3l-0.1,11.6c-3.5,9.3-7.6,17-12.2,22.9c5.6,3.4,13,6.3,20.5,8.4l-8.3,16
				L737.9,194.3z M683.3,122.3v3.1c1.3-1,2.5-2,3.5-3.1H683.3z M461.2,192.1c-11.9-0.5-14.4-7.6-14.4-13.4v-18.5H434v-14.8
				c-1.9,3.3-3.5,5.3-5.6,7.8l-8.7-11.7c4.2-4.2,9.2-15.3,11.6-22.7h-7.7v-14.5H434V90.6h14.4v13.8h5.8v-12H491v11.2
				c-0.2,0.3-0.5,0.6-0.8,0.9h5.7v-14h14.5v13.8H522v14.5h-6.7c2.3,7.2,5.5,14,10.5,18.9L518,153c-2.9-3.3-5.2-6.4-7.6-10.9v15.6
				l0.1-0.1c5,5.5,9.9,12.2,14.7,19.6l-13.8,11c-4.1-7.2-9-14.3-14.6-21.3l10.6-7.2h-11.5v-16.6c-1.9,3.3-3.9,6.4-6.1,9.5
				l-10.5-11.1c0,5.3-0.8,8.9-2.2,11.2c3.8,4.1,7.6,8.8,11.1,13.7l-13.3,10.5c-3.1-4.9-7-10-11.8-15.5l6.8-4.8
				c-3.1,0.4-6.6,0-9.1-1.3l-1.4-8.4c-1.1,1-2.4,2.1-3.6,3.1l-7.5-7.6v14.9h14.2v17.3c0,1.7,0.9,2.2,4,2.4c3.5,0.2,9,0.2,17.5-0.2
				c1.7-0.1,2.3-0.7,2.5-1.7c0.6-2.7,1-7.4,1-7.4l16,9.5c-1.8,9.8-3.3,13.8-12.6,14.5C480.2,192.6,472.7,192.6,461.2,192.1z
				 M464.2,142.5c0.4,0.1,0.7,0.2,1.1,0.2c0-0.3,0-0.8,0.1-1.4C464.9,141.6,464.5,142.1,464.2,142.5L464.2,142.5z M479.4,133.2v7.6
				c1.3-1.7,2.6-3.3,3.8-5.2L479.4,133.2L479.4,133.2z M457.4,125.7c0.2,0.2,1.3,1.3,1.6,1.5l-3.5,4.4c2-1.9,3.8-3.9,5.4-5.9
				L457.4,125.7L457.4,125.7z M449,118.8c1.5,1,2.9,2.1,4.3,3.2v-3.2H449z M481.2,112.6h4.8v-4C484.5,110,482.8,111.3,481.2,112.6z
				 M455.3,112.6h4.6c-1-0.9-2.6-2.2-3.6-3l6-4.1h-7V112.6L455.3,112.6z M437.9,189.8L423,182c3.6-7.2,6.3-14.5,8-21.7l15.7,5
				C444.3,174.5,441.4,182.7,437.9,189.8L437.9,189.8z M661,175h7.9v-7.6H661V175z M707,151.1c1.8,3.3,3.9,6.2,6.4,8.8
				c2.2-2.6,4.1-5.5,5.8-8.8H707z M605,127.1h6.7v-0.6H605V127.1z M605,111.7h6.7v-0.6H605V111.7z M12.1,196.7
				c54.6-63.3,138.8,12.8,208.3-41.1l69.1,16.4C190.7,267.6,94.6,153.9,12.1,196.7L12.1,196.7z M149,67c7.9-17.1,30.2-67,30.2-67
				C125.8,20.7,88.7,51.2,60.3,86c0,0,46.3,0,61.7,0C139.6,86,141.7,82.9,149,67z M40.6,112.7c-15.6,23.4-28.5,48-40.5,72.5l4.7,4.7
				c40.3-47.6,83.4-59.9,135.8-55.7V102c0,0-60.8,0-81.1,0C48,102,47.4,102.6,40.6,112.7z M200.8,110.3c-0.1,4.1-0.4,7.2-4.3,7.3
				c-3.9,0.1-4.2-3.4-4.3-7.1l-12.8,1.7c0.8,6.1,2.1,15.4,18,15.4c19.1,0,16.7-18.6,16.7-20.5V83.6h-13.4L200.8,110.3L200.8,110.3z
				 M223,126.9h10.9v-33l8.4,33h9.9l8.4-33v33h10.9V83.6H254l-6.7,26.3l-6.8-26.3h-17.6L223,126.9L223,126.9z"/>
		</defs>
		<clipPath id="SVGID_3">
			<use xlink:href="#SVGID_2"  style="overflow:visible;"/>
		</clipPath>
		
			<linearGradient id="SVGID_4" gradientUnits="userSpaceOnUse" x1="-400" y1="106" x2="0" y2="106">
			<stop  offset="0" style="stop-color:#FFFFFF;stop-opacity:0"/>
			<stop  offset="0.5" style="stop-color:#FFFFFF"/>
			<stop  offset="1" style="stop-color:#FFFFFF;stop-opacity:0"/>
      <animate
      attributeName="x1" from="-300" to="750"
      dur="3s" repeatCount="indefinite" />
      <animate
      attributeName="x2" from="-0" to="1050"
      dur="3s" repeatCount="indefinite" />
		</linearGradient>
			<rect class="an1"  style="clip-path:url(#SVGID_3);fill:url(#SVGID_4);" width="747.1" height="212" />
	</g>
	<path d="M338.4,17.5v5.9h-2.5v4l2.8-3.3c0.4,0.3,0.8,0.5,1.1,0.8c1.7-3.1,3-6.1,4.2-9.1l5.1,2.3
		c-1.7,3.8-3.3,7.1-4.8,9.7c0.4,0.2,0.7,0.5,0.9,0.7c1.2-2.4,2.3-4.8,3.3-7.1l5.5,2.3c-1.9,3.8-3.8,7.3-5.5,10.4l4.1-0.9
		c1.1,3.1,1.9,6.3,2.3,9.5l-5.2,1.4c-0.1-0.7-0.2-1.4-0.3-2.1c-3.4,1.2-7.1,2.2-11,3l-1.8-5.5c0.9-0.1,1.8-0.3,2.6-0.5
		c1.2-1.8,2.2-3.5,3.2-5.3c-2.2-1.6-4.3-3.2-6.5-4.8v40.5H330v-9.6c-3.3,0.9-6.9,1.7-10.6,2.2l-1.2-6.5c0.8-0.1,1.5-0.1,2.3-0.2
		V23.5h-2.3v-5.9h20.2V17.5z M329.9,29.7v-6.2h-3.5v6.2H329.9z M329.9,41.7v-6.2h-3.5v6.2H329.9z M329.9,53.8v-6.4h-3.5v6.9
		C327.7,54.2,328.8,54.1,329.9,53.8z M344.4,46.1v6.4h3.6v-7.6h5.8v7.8c0,9.9-3.3,14.6-12.6,16.6l-2.8-5.8c4.7-0.9,7.2-2,8.8-5.5
		h-2.8v1.7h-5.5V46.1H344.4z M347.9,35.3c-0.5,0.8-0.9,1.5-1.3,2.2c0.6-0.1,1.2-0.2,1.8-0.4C348.2,36.5,348,35.9,347.9,35.3
		L347.9,35.3z M365.3,18.2c-1.6,3.8-3.1,7.1-4.4,9.8c0.4,0.2,0.7,0.4,1,0.6c1.1-2.4,2.1-4.8,2.9-7.2l5.5,2.3c-1.5,3.4-3,6.5-4.3,9.4
		l3.6-0.8c1,3.5,1.7,7,2.1,10.5l-4.8,1.2c-0.1-0.7-0.2-1.5-0.3-2.2c-3.4,0.8-7.1,1.4-11,1.9l-0.9-5.5c0.8-0.1,1.7-0.2,2.5-0.3
		c0.8-1.4,1.5-2.8,2.2-4.2c-2.5-1.5-5-3.1-7.4-4.7l3.4-4.1c0.3,0.2,0.6,0.4,0.8,0.5c1.5-3.2,2.9-6.3,4-9.5L365.3,18.2L365.3,18.2z
		 M361.6,45.1v7.4h3.2v-6.4h5.5v15.2h-5.5V58h-3.2v11.3h-5.8V45.1H361.6z M365,34.9c-0.4,0.8-0.7,1.5-1.1,2.1
		c0.5-0.1,1.1-0.2,1.6-0.3C365.4,36.1,365.2,35.5,365,34.9z M383.9,23.5v-7.4h5.7v7.4h3.7v5.8h-3.7v1.3c1.9,1.2,3.6,2.6,5.3,4.3
		l-3.9,4.8c-0.5-0.5-1-1.1-1.4-1.5v13.3H384v-11c-1.5,2.9-2.8,5.1-3.9,6.5l-3.4-4.6c2.3-2.7,5-9,6.3-13.1h-4.5v-5.8H383.9
		L383.9,23.5z M388.5,55.7c-1.2,4.4-2.5,8.1-3.9,11.1l-6.2-3.2c1.6-3.3,2.8-6.6,3.6-9.9L388.5,55.7L388.5,55.7z M412.4,17v4.6
		c-2,2.2-4.3,4.3-6.9,5.9c0.1,0.2,0.2,0.3,0.2,0.4h5.9v-4.4h5.3v-7.4h5.8v7.4h6.2v5.8H425c1.2,4.1,3,8.4,5.8,11.3l-3.2,6.2
		c-1.7-2-3.5-5.1-4.8-8.5v12.9H417v-12c-1.4,2.9-2.9,5.4-4.3,7.6l-4.3-4.6c2.7-3.3,5.4-8.7,6.9-12.9h-1.9v2.5
		c-1.2,2.6-2.5,4.8-3.9,6.4l-3.2-2.1c-0.1,2.8,0.4,9.9-1,11.7c-1.1,1.9-4.9,2.1-7,1.2l-1-5.8c1,0.5,2,0.7,3,0.7
		c0.6,0,0.4-3.2,0.5-4.6c-1.1,1.4-2.2,2.6-3.5,3.8c-0.8,0.8-1.7,1.6-2.5,2.3l-4.1-4.1c3.6-2.6,6.5-5.4,8.6-8.5h-5v-5h5.1
		c-0.8-0.8-1.8-1.7-2.8-2.5l4.4-3c0.4,0.5,0.8,0.8,1.2,1.2c0.8-0.4,1.7-0.9,2.4-1.4h-9.8V17H412.4z M397.2,51.8v8.3
		c0,2.1,1.3,2.3,6.6,2.3c7.9,0,7.9,0.6,8.6-4.4l6.7,4c-1.3,6.4-1.9,6.6-15.3,6.6c-6.5,0-13,0.5-13-6.4V51.8H397.2L397.2,51.8z
		 M410.6,55.5l-5.5,4.4c-1.5-2.2-3.2-4.4-5.1-6.7l5.5-3.9C407.2,51,408.9,53.1,410.6,55.5z M406.2,35.2c0.5-0.8,0.9-1.5,1.3-2.4
		h-1.3V35.2z M430.4,61.2l-5.8,4.6c-1.9-3.2-4.1-6.5-6.7-9.7l5.7-3.9C425.9,54.8,428.1,57.8,430.4,61.2z M441.4,26.2V16.1h6v10.1
		h3.6V32h-3.6v3.2c2.2,2.1,4.2,4.1,5.9,6.1l-0.4-2.4c0.9,0,1.8,0,2.6-0.1c1.2-1.8,2.2-3.6,3.2-5.4c-2.2-1.7-4.6-3.2-7.1-4.5l3.2-4.1
		c0.4,0.2,0.7,0.4,1,0.5c1.6-3.1,2.9-6.2,4-9.3l5.1,2.3c-1.8,3.7-3.3,6.8-4.8,9.5c0.4,0.2,0.7,0.5,1.1,0.7c0.8-1.6,1.5-3.1,2.1-4.7
		l4.5,2c-0.2-3-0.3-6.2-0.3-9.6l6.2-0.5c0,4,0,7.6,0.2,10.9l0.8-1.4c0.2,0.2,0.5,0.4,0.8,0.5c1.4-3.2,2.6-6.5,3.6-9.7l5.1,2.3
		c-1.7,4-3.1,7.4-4.5,10.1c0.4,0.4,0.7,0.7,1.1,0.9c1-2.2,1.9-4.4,2.7-6.6l5.1,2.3c-1.2,2.7-2.4,5.1-3.5,7.4l2.4-0.7
		c1.2,2.9,2.1,6.1,2.8,9.7l-4.6,1.4c-0.1-0.5-0.2-1.1-0.3-1.6c-0.8,0.4-1.6,0.6-2.3,0.7c1.3,0.8,2.5,1.7,3.5,2.5l-1.7,1.8h4.7v5.5
		h-11.9c0.4,1.3,0.8,2.5,1.3,3.7c0.7-0.9,1.4-1.9,2.1-3l5.3,2.5c-1.4,2.5-2.9,4.7-4.4,6.4c1.9,2.7,2.1,1.4,2.6-2.2l5.7,2.3
		c-1.7,7.7-8,10.5-12.6,3.7c-2.4,1.7-5,3-7.8,4.2l-3-5.3c3.2-1.2,5.8-2.5,7.8-4.1c-1.1-2.4-2.1-5.1-2.8-8.1h-8.3
		c0,0.8-0.1,1.5-0.1,2.2c2.8,1.4,5.2,2.7,7.3,4l-3.4,4.8c-1.6-1.2-3.2-2.2-4.8-3.2c-0.8,3.6-2.1,6.9-4.2,9.7l-5.5-3.5
		c3.2-4.2,4.2-8.2,4.6-14h-4v-5.5h17c-0.3-1.2-0.5-2.5-0.8-3.8l-4,1.2c-0.1-0.5-0.2-1-0.2-1.5c-3.3,1.2-7.1,1.8-11.2,1.9l-0.2-1.4
		l-3.4,4.8c-0.9-1.6-1.9-3.1-2.8-4.4v26.1h-6V49.5c-0.7,2.2-1.5,4.3-2.3,6.2l-3.6-4.6c2.2-4.5,4.7-12.5,5.9-18.4V32h-4.8v-5.8
		L441.4,26.2L441.4,26.2z M463.3,35.3c-0.6,1-1.1,1.9-1.7,2.8c0.8-0.1,1.6-0.4,2.3-0.6C463.7,36.8,463.5,36,463.3,35.3L463.3,35.3z
		 M467.7,27.2c-1.3,2.5-2.5,4.8-3.6,6.8l3.1-0.9c0.6,1.5,1.1,3.2,1.5,4.8C468.2,34.6,467.9,31.1,467.7,27.2L467.7,27.2z M477.9,44.9
		l2.1-2.5c-1.4,0.2-2.8,0.4-4.1,0.4l-0.9-5.1c0.5,0,1.1,0,1.6-0.1c0.7-1.2,1.4-2.5,1.9-3.8c-1.5-1.2-3.1-2.2-4.5-3.2
		c0.4,6.4,1,11.1,2,15.6h3.9C479.2,45.8,478.5,45.3,477.9,44.9L477.9,44.9z M483.4,35c-0.4,0.7-0.7,1.3-1,1.9c0.5-0.1,1-0.2,1.5-0.3
		C483.7,36.1,483.6,35.6,483.4,35z M501.2,25.5v-9.4h6.2v9.4h5.1v6.2h-5.1v3.2c2.7,2.6,5.1,5.2,7.1,7.7l-4.1,5.7
		c-1.1-1.8-2.1-3.4-3-5v25.9h-6.2V48.1c-0.9,2.7-1.9,5.2-3,7.6l-3.9-4.8c2.5-4.6,5.4-12.9,6.7-19.1h-5.6v-6.2L501.2,25.5L501.2,25.5
		z M520.7,18.6v-2.5h6.4v2.5h6v-2.5h6.4v2.5h6.7v5.5h-6.7V26h5.8v5.5h-5.8v1.8h7.6v5.5h-13.8v1.6h12.2v15.2h2.9V61h-2.9
		c0,7.6-4.6,9-12.2,8.1l-1.4-6.7c5.2,1.1,6.9,1.1,7.3-1.4h-17.6v8.3h-6.2V61h-3.7v-5.3h3.7V40.5h11.7v-1.6h-14v-5.5h7.6v-1.8H515V26
		h5.8v-1.8h-7.4v-5.5h7.3V18.6z M527.1,48.3V46h-5.5v2.3H527.1z M527.1,55.7v-2.3h-5.5v2.3H527.1z M533.1,24.2h-6V26h6V24.2z
		 M533.1,33.4v-1.8h-6v1.8H533.1z M533.3,48.3h5.9V46h-5.9V48.3z M539.3,55.7v-2.3h-5.9v2.3H539.3z"/>
	<path d="M364.8,104.1c-9.6,2.9-20.6,5.1-33.2,6.6v14.9h27.1c5.9-9.3,11-22.9,13.8-33.2l12.1,3.5
		c-1.3,4.7-2.6,9.4-4.2,13.8h32.9c-1.7,10.1-4.7,20.2-9.4,30.1l-9.5-5.6c1.9-4.1,3.3-8.4,4.4-12.9H392c-0.5,4.7-0.9,9.2-1.4,13.2
		c1.5,8.4,3.5,15.6,5.7,21.7c3.3,9.6,8.7,18.1,16.3,25.4l-10.4,9.9c-9.1-10.7-13.4-20.6-16.7-34.1c-4.1,13.2-11.1,25.1-19.8,34.5
		l-8.1-10.8c14.9-16.7,19.9-34.4,22.3-59.9h-4c-2.5,5.8-5.2,10.9-8,15.1l-6.8-5.2v6.4H353v54h-12.1v-54h-9.2
		c-0.8,24.7-0.8,36.4-10.2,53.1l-9.5-7.7c10.7-18.9,7.6-54.3,8.1-82.8c15.6-1.5,28.3-4.1,38.2-7.4L364.8,104.1z M435.8,106.2V92.4
		h10.7v13.8h6.9V117h-6.9v2.4c3.5,2.3,6.8,5,9.9,8l-7.3,9c-0.9-1-1.8-2-2.6-2.9v24.8h-10.7V138c-2.8,5.5-5.2,9.5-7.4,12.1l-6.4-8.6
		c4.4-5,9.4-16.8,11.8-24.6h-8.4v-10.8L435.8,106.2L435.8,106.2z M444.4,166.5c-2.2,8.3-4.7,15.2-7.4,20.7l-11.6-6.1
		c3-6.2,5.3-12.4,6.8-18.5L444.4,166.5z M489.2,94.1v8.6c-3.7,4.2-8,8-12.9,11c0.2,0.3,0.3,0.6,0.5,0.7h11.1v-8.3h9.9V92.4h10.8
		v13.8h11.6V117h-7.4c2.3,7.7,5.6,15.7,10.8,21l-6,11.7c-3.2-3.7-6.5-9.6-9-16v24.2h-10.8v-22.3c-2.6,5.5-5.4,10.1-8.1,14.1
		l-8.1-8.6c5.1-6.3,10-16.2,13-24.2h-3.5v4.7c-2.2,4.9-4.7,8.9-7.4,12l-6.1-3.9c-0.1,5.2,0.7,18.6-1.9,21.9
		c-2.1,3.5-9.2,3.9-13.1,2.3l-1.8-10.8c2.5,0.9,4.6,1.7,5.6,1.3c0.8-0.3,1-2.2,0.9-8.5c-3.3,4.3-7.2,8-11.2,11.5l-7.7-7.7
		c6.8-4.9,12.2-10.1,16.1-16h-9.3v-9.4h9.5c-1.5-1.5-3.3-3.2-5.2-4.7l8.3-5.6c0.8,0.9,1.5,1.5,2.2,2.2c1.5-0.8,3.1-1.7,4.5-2.6H456
		v-9.5H489.2L489.2,94.1z M460.7,159.2v15.4c0,4.6,4.8,4.3,11.2,4.3c17,0,16.1,0.3,17.2-8.2l12.4,7.4c-2.6,11.2-1.6,12.5-29.7,12.5
		c-10,0-23.9,0.4-23.3-12v-19.4H460.7L460.7,159.2z M485.7,166.1l-10.4,8.2c-2.8-4.2-6-8.3-9.5-12.5l10.4-7.3
		C479.4,157.8,482.5,161.7,485.7,166.1z M477.5,128.2c0.9-1.4,1.7-2.9,2.4-4.4h-2.4V128.2z M522.7,176.9l-10.8,8.6
		c-3.5-6.1-7.7-12.1-12.4-18.1l10.7-7.3C514.4,164.8,518.6,170.5,522.7,176.9L522.7,176.9z M565.3,96.2v9.5c-3.3,8-6.7,15-10.2,21.2
		h14.2c-2.1,19.7-4.8,31.5-10.5,42.6c13.2,12.1,51,8.6,74.1,7.4l-4.3,13.3c-22.7-0.1-64,2-75.8-10.8c-3.3,4.5-7.2,8.8-11.6,13
		l-8.3-9c5.1-4.2,9.2-8.4,12.2-12.7c-3.4-5.1-6.1-11.4-7.9-18.9l9-3.9c1.5,4.5,3.2,8.4,5.1,11.7c2.3-5.7,4-12.8,4.9-21.2h-8.5
		c-1.2,1.8-2.4,3.3-3.5,4.7l-9-6c6-8.6,11.2-18.2,15.6-28.9h-15.6v-12H565.3L565.3,96.2z M591.2,98v-5.6h12V98h22.4v15.6h6.9v11.2
		h-6.9v15.6h-22.4v3.4h25v11.3h-25v3.9h28v11h-28v6.5h-12V170h-25.9v-11.2h25.9v-3.9h-20.7v-11.3h20.7v-3.4h-18.5V129h18.5v-4.3
		h-24.6v-11.2h24.6v-4.3h-18.1V98H591.2z M603.2,113.6h10.4v-4.3h-10.4V113.6z M603.2,129h10.4v-4.3h-10.4V129z M686.3,108.4v12
		h-42.6v-12H686.3z M682.4,188h-23.3v4h-11.7v-37.5h34.9L682.4,188L682.4,188z M681.1,138.9v11.7h-32.7v-11.7H681.1z M681.5,123.8
		v11.7h-32.7v-11.7H681.5z M681.1,94.1v11.6h-31.8V94.1H681.1z M670.7,176.9v-11.3h-11.6v11.3H670.7z M736.2,137.3v9
		c-3.7,9.9-8.1,18-13.2,24c5.7,3.8,12.6,6.7,20.6,9L737,192c-8.8-3.1-16.6-7.3-23.5-12.7c-7,5.7-15.2,9.9-24.3,12.7l-6-11.7
		c8.3-2.2,15.3-5.4,21.3-9.6c-5.5-6.1-10-13.2-13.4-21.4h-4.9v-12H736.2L736.2,137.3z M728.9,93.7v22.4c0,4.2,0.5,5.2,2.2,5.2
		c2.9,0,2.5-1.1,3-10.4l11.1,5.6c-1.3,12.4-2,15.6-13.5,15.6c-12.4,0-15.6-2.1-15.6-12.6v-13.8h-11.2v5.2c0,14.8-5.3,20.5-10.8,25.5
		l-10.2-9c6.1-4.3,9-8.7,9-16.4V93.7H728.9z M713.5,162.6c3.5-3.9,6.4-8.3,8.7-13.3H704C706.4,154.4,709.5,158.8,713.5,162.6z"/>
	
		<linearGradient id="SVGID_5" gradientUnits="userSpaceOnUse" x1="17.2484" y1="1.9549" x2="262.195" y2="143.1596" gradientTransform="matrix(1 0 0 -1 0 214)">
		<stop  offset="5.000000e-02" style="stop-color:#EFCA84"/>
		<stop  offset="0.11" style="stop-color:#E3B75E"/>
		<stop  offset="0.27" style="stop-color:#C78A03"/>
		<stop  offset="0.59" style="stop-color:#F9F3E5"/>
		<stop  offset="0.81" style="stop-color:#F4DB8C"/>
		<stop  offset="0.99" style="stop-color:#F0CC7E"/>
	</linearGradient>
	<path style="fill:url(#SVGID_5);" d="M176.7,2.4c0,0-21.7,48-28.9,64.1
		c-6.4,14.3-8.6,18.3-24.3,18.3c-2.1,0-58.4,0-60.4,0C93.9,47.5,131.1,20.6,176.7,2.4z M270.3,84.9v40.7H262V84.9H270.3z
		 M261.6,84.9c-1.4,5.7-8.8,34.4-10.4,40.7h-7.9c-1.6-6.4-8.9-34.9-10.4-40.7h6.6c0.9,3.6,7,27.3,7.8,30.3
		c0.7-2.8,6.9-26.8,7.7-30.3C255,84.9,261.6,84.9,261.6,84.9z M232.6,84.9v40.7h-8.3V84.9H232.6z M212.9,84.9v22.2
		c0,6,0.8,19.2-15.4,19.2c-9.9,0-15.1-3-16.6-12.9L191,112c0.1,3.6,1.6,6.9,5.5,6.9c4,0,5.7-3.1,5.7-8.6V84.9H212.9 M59.4,103.3
		c19.9,0,79.8,0,79.8,0v29.4C85.5,128.1,41,144.4,4.6,187.9l-3-3c10.9-22,24.2-48,40.1-71.5C47.1,105.5,47.5,103.3,59.4,103.3z
		 M220.7,157l66.2,15.7c-50.1,48-100.5,40.4-146.7,29.2c-42.5-10.3-81.8-25.8-122.5-9.2c29.4-29,68.3-21.7,106.2-17.2
		C156.7,179.3,188.3,180.9,220.7,157"/>
</g>
</svg>
  -->

<div class="t1" data-aos="zoom-in" data-aos-delay="200" v-if="!isMobile">三重國小站600米｜大智街 X 六張街｜2983-9777
</div>
<div class="t1" data-aos="zoom-in" data-aos-delay="200" v-else>三重國小站600米<span>大智街 X 六張街｜2983-9777</span>
</div>
  <svg class="t2" data-aos="zoom-in" data-aos-delay="400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 506 21">
<path d="M0.3,17.7C0,17.5,0,17.4,0,16.8c0-1.2,0.1-2.1,0.1-2.5c0-0.4,0.4-0.5,0.4-0.1c-0.2,2.1,1.7,3.3,3.7,3.2
	c2.3,0,3.5-1.6,3.5-3.1c0.3-1.8-2-3.6-3.9-5c-5-3.4-4-9.1,1.8-9.2c1.3,0,2.2,0.3,3.1,0.4c0.2,0,0.2,0,0.2,0.2S8.9,1.6,8.9,3.1
	c0.1,0.6-0.3,0.7-0.4,0.2C8.1,1.8,8.2,1.2,5.1,1c-1.7,0-3,1-3,2.7c-0.2,1.3,1.6,3,3.6,4.4C13.4,13.3,7.5,20.7,0.3,17.7L0.3,17.7z
	 M18.4,3.5c0.5-1.1,0.6-0.9,1,0c0.4,0.8,3.8,9,5.1,12c0.8,1.8,1.4,2.3,2.7,2.3c0.4,0,0.4,0.3,0,0.3c-0.3,0-2.6,0-4.1,0
	c-0.4,0-0.2-0.2-0.1-0.3c0.1,0,0.3-0.2,0.2-0.5l-1.9-4.5c0,0-0.1-0.1-0.2-0.1h-4.9c0,0-0.2,0-0.2,0.2c0,0-0.9,2.4-1.2,3.2
	c-0.5,1.3-0.6,1.9,0.6,1.9c0.3,0,0.3,0.3-0.1,0.3c-1.7-0.1-2.3-0.1-4.3,0c-0.3,0-0.2-0.4,0-0.4c1.5,0,1.8-0.5,2.4-1.8
	C14.9,12.8,18.4,3.5,18.4,3.5z M20.8,11.7c0,0,0.1,0,0.1-0.1l-2.1-5.4c-0.1-0.3-0.2-0.3-0.3,0l-2,5.4c0,0,0,0.1,0.1,0.1H20.8
	L20.8,11.7z M31.2,15.3c0,1.6,0.2,2.1,0.8,2.3c0.4,0.1,0.9,0.1,1.1,0.1c0.3,0.1,0.2,0.4-0.2,0.3c-2.6-0.1-1.8-0.1-4.3,0
	c-0.3,0.1-0.5-0.3-0.2-0.3c1.3-0.1,1.5-0.2,1.5-2.6c0-2.8,0-11.3,0-11.3c0-1.5,0.5-1,1.1-0.4c0.5,0.5,10.3,10.2,11.8,11.8l-0.2-9.9
	c0-2-0.4-1.8-1.8-1.9c-0.3,0-0.2-0.3,0.1-0.3c1.7,0,2.6,0,4.2,0c0.5,0,0.5,0.3,0.1,0.3c-1,0-1.3,0.1-1.3,1.8v11.6
	c0,1.3-0.1,1.4-0.2,1.4c-0.2,0-0.5-0.2-1.6-1.2C41,16.1,32.1,7.2,31,6C31,6,31.2,13,31.2,15.3z M107.4,15.3c0,1.6,0.2,2.1,0.8,2.3
	c0.4,0.1,0.9,0.1,1.1,0.1c0.3,0.1,0.2,0.4-0.2,0.3c-2.6-0.1-1.8-0.1-4.3,0c-0.3,0.1-0.5-0.3-0.2-0.3c1.3-0.1,1.5-0.2,1.5-2.6
	c0-2.8,0-11.3,0-11.3c0-1.5,0.5-1,1.1-0.4c0.5,0.5,10.3,10.2,11.8,11.8l-0.2-9.9c0-2-0.4-1.8-1.8-1.9c-0.3,0-0.2-0.3,0.1-0.3
	c1.7,0,2.6,0,4.2,0c0.5,0,0.5,0.3,0.1,0.3c-1,0-1.3,0.1-1.3,1.8v11.6c0,1.3-0.1,1.4-0.2,1.4c-0.2,0-0.5-0.2-1.6-1.2
	c-1.1-0.9-10-9.8-11.1-10.9C107.2,6,107.4,13,107.4,15.3z M284.2,15.3c0,1.6,0.2,2.1,0.8,2.3c0.4,0.1,0.9,0.1,1.1,0.1
	c0.3,0.1,0.2,0.4-0.2,0.3c-2.6-0.1-1.8-0.1-4.3,0c-0.3,0.1-0.5-0.3-0.2-0.3c1.3-0.1,1.5-0.2,1.5-2.6c0-2.8,0-11.3,0-11.3
	c0-1.5,0.5-1,1.1-0.4c0.5,0.5,10.3,10.2,11.8,11.8l-0.2-9.9c0-2-0.4-1.8-1.8-1.9c-0.3,0-0.2-0.3,0.1-0.3c1.7,0,2.6,0,4.2,0
	c0.5,0,0.5,0.3,0.1,0.3c-1,0-1.3,0.1-1.3,1.8v11.6c0,1.3-0.1,1.4-0.2,1.4c-0.2,0-0.5-0.2-1.6-1.2C294,16.1,285.1,7.2,284,6
	C284,6,284.2,13,284.2,15.3z M472.6,15.3c0,1.6,0.2,2.1,0.8,2.3c0.4,0.1,0.9,0.1,1.1,0.1c0.3,0.1,0.2,0.4-0.2,0.3
	c-2.6-0.1-1.8-0.1-4.3,0c-0.3,0.1-0.5-0.3-0.2-0.3c1.3-0.1,1.5-0.2,1.5-2.6c0-2.8,0-11.3,0-11.3c0-1.5,0.5-1,1.1-0.4
	c0.5,0.5,10.3,10.2,11.8,11.8L484,5.3c0-2-0.4-1.8-1.8-1.9c-0.3,0-0.2-0.3,0.1-0.3c1.7,0,2.6,0,4.2,0c0.5,0,0.5,0.3,0.1,0.3
	c-1,0-1.3,0.1-1.3,1.8v11.6c0,1.3-0.1,1.4-0.2,1.4c-0.2,0-0.5-0.2-1.6-1.2c-1.1-0.9-10-9.8-11.1-10.9C472.4,6,472.6,13,472.6,15.3z
	 M56.7,2.8c2.1,0,3.7,0.4,5.3,0.5c0.2,0,0.3,0.1,0.3,0.2c-0.1,0.4-0.1,2.8-0.1,3.2c0,0.6-0.5,0.6-0.4,0c-0.2-2.5-2.3-2.9-5.1-3.1
	c-5.1,0-6.7,2.5-6.7,6.5c0,3.8,3.1,7.2,7.7,7.2c2.2,0.1,4-0.6,4.4-2.6c0-0.4,0.4-0.3,0.4,0c0,0.1-0.3,1.9-0.5,2.5
	c-0.1,0.4-0.2,0.4-0.5,0.6c-1.7,0.7-11.4,1.5-13.1-4.8C46.6,7.1,50.2,2.8,56.7,2.8z M259.2,2.8c2.1,0,3.7,0.4,5.3,0.5
	c0.2,0,0.3,0.1,0.3,0.2c-0.1,0.4-0.1,2.8-0.1,3.2c0,0.6-0.5,0.6-0.4,0c-0.2-2.5-2.3-2.9-5.1-3.1c-5.1,0-6.7,2.5-6.7,6.5
	c0,3.8,3.1,7.2,7.7,7.2c2.2,0.1,4-0.6,4.4-2.6c0-0.4,0.4-0.3,0.4,0c0,0.1-0.3,1.9-0.5,2.5c-0.1,0.4-0.2,0.4-0.5,0.6
	c-1.7,0.7-11.4,1.5-13.1-4.8C249.1,7.1,252.7,2.8,259.2,2.8z M80.6,16.5c0,0.6,0.1,1,0.8,1c0.3,0,0.8,0.1,0.9,0.1
	c0.4,0.1,0.2,0.4-0.1,0.3c-2.6,0-2.5-0.1-4.7,0c-0.2,0-0.3,0-0.3-0.2c0-0.3,0.6-0.1,0.8-0.3c0.5-0.1,0.5-0.5,0.6-1.1
	c0.1-0.8,0.1-6.2,0.1-6.2s0-0.1-0.1-0.1h-8.9c0,0-0.1,0-0.1,0.1c0,0,0.1,5.4,0.1,6.2c0,1.1,1,1.1,1.7,1.1c0.3,0.1,0.2,0.4-0.2,0.3
	c-2.7-0.1-2.5-0.1-4.8,0c-0.2,0-0.3,0-0.3-0.2c0-0.3,0.6-0.1,0.8-0.3c0.4-0.1,0.5-0.5,0.6-1c0.2-2.1,0.1-10.3,0.1-12
	c0-0.7-0.2-1-0.9-1.1c-0.2,0-0.9,0.1-0.9-0.2c0-0.1,0.1-0.2,0.3-0.2c2.4,0,2.4,0,4.6,0c0.2,0,0.3,0,0.3,0.2
	c-0.1,0.3-0.4,0.1-0.7,0.2c-0.6,0.1-0.7,0.4-0.8,1.1c0,0.2,0,2.2,0,4.7c0,0,0,0.1,0.1,0.1h8.9c0,0,0.1,0,0.1-0.1c0-2.5,0-4.5,0-4.7
	c0-0.7-0.1-1-0.9-1.1c-0.2,0-0.9,0.1-0.9-0.2c0-0.1,0.1-0.2,0.3-0.2c2.3,0,2.4,0,4.5,0c0.2,0,0.3,0,0.3,0.2
	c-0.1,0.3-0.4,0.1-0.7,0.2c-0.6,0.1-0.7,0.4-0.7,1.1C80.5,5.2,80.5,15.1,80.6,16.5z M93.6,2.8c4.8,0,8.5,2.9,8.5,7.4
	c-0.2,11-17,10.4-16.9,0.3C85.2,7.3,87.7,2.8,93.6,2.8z M94.1,17.5c1.9,0,5.8-1,5.8-6.7c0.3-9.2-12.6-9.9-12.5-0.8
	C87.4,14.6,90.2,17.5,94.1,17.5z M379.8,2.8c4.8,0,8.5,2.9,8.5,7.4c-0.2,11-17,10.4-16.9,0.3C371.4,7.3,373.9,2.8,379.8,2.8z
	 M380.3,17.5c1.9,0,5.8-1,5.8-6.7c0.3-9.2-12.6-9.9-12.5-0.8C373.6,14.6,376.4,17.5,380.3,17.5z M136.5,12.9c0-1.6-0.1-1.9-1.6-1.9
	c-0.4,0-0.6-0.4,0-0.4c1.6,0,2.4,0,4.6,0c0.6,0,0.4,0.4-0.1,0.4c-1,0-1,0.4-1.1,1.1c0,1,0,3.1,0,4.2c0,0.9,0,0.9-0.3,1.1
	c-1.4,0.7-3.3,0.9-4.5,0.9c-1.6,0-4.6-0.2-7-2.1c-3.8-3-3.2-9.9,1-12.1c2.8-1.6,6.6-1.4,9-0.9c0.5,0.1,1.2,0.2,1.7,0.2
	c0.2,0,0.3,0.1,0.3,0.2c0,0.3-0.1,0.8-0.1,2.8c0,0.5-0.4,0.6-0.4,0.1c-0.1-3.1-7-3.7-9.9-1.6c-3.3,2.4-2.3,8.5,0.1,10.4
	c2.3,2,5.2,2.5,7.8,1.7c0.3-0.1,0.5-0.2,0.5-0.6C136.6,16.4,136.6,13.8,136.5,12.9z M450.1,12.8c0-1.6-0.1-1.9-1.6-1.9
	c-0.4,0-0.6-0.4,0-0.4c1.6,0,2.4,0,4.6,0c0.6,0,0.4,0.4-0.1,0.4c-1,0-1,0.4-1.1,1.1c0,1,0,3.1,0,4.2c0,0.9,0,0.9-0.3,1.1
	c-1.4,0.7-3.3,0.9-4.5,0.9c-1.6,0-4.6-0.2-7-2.1c-3.8-3-3.2-9.9,1-12.1c2.8-1.6,6.6-1.4,9-0.9c0.5,0.1,1.2,0.2,1.7,0.2
	c0.2,0,0.3,0.1,0.3,0.2c0,0.3-0.1,0.8-0.1,2.8c0,0.5-0.4,0.6-0.4,0.1c-0.1-3.1-7-3.7-9.9-1.6c-3.3,2.4-2.3,8.5,0.1,10.4
	c2.3,2,5.2,2.5,7.8,1.7c0.3-0.1,0.5-0.2,0.5-0.6C450.1,16.4,450.1,13.7,450.1,12.8z M153.5,15.8c-3.8-3.5-4-9.4-0.3-13.2
	c2.9-3.1,8.8-2.7,13.4-1.9c0.3,0,0.3,0.1,0.3,0.3c-0.1,0.5-0.2,3.2-0.2,3.7c0,0.7-0.5,0.6-0.4,0c0.1-4.9-9.8-4.2-11.4-2
	c-1.8,1.6-2.2,3.7-2.2,6.1c0,4.4,3.6,8.5,8.8,8.5c2.6,0.1,4.8-0.7,5.1-3.1c0-0.5,0.5-0.5,0.4,0c0,0.2-0.3,2.2-0.5,3
	c-0.1,0.4-0.2,0.5-0.6,0.7C162.2,19,155.8,18.3,153.5,15.8z M172.2,4.5c0-1.1-0.7-1.2-1.6-1.2c-0.3,0-0.2-0.4,0.2-0.3
	c2.5,0,2.6,0,5,0c5.5,0,5.5,4.5,2.4,6.5c5.5,1.3,5.1,8.5-1.6,8.5c-1.7,0-3.7-0.2-5.4-0.1c-0.4,0-0.5-0.3-0.2-0.3
	c0.8,0,1.2-0.2,1.2-1.2C172.3,13.5,172.1,7.5,172.2,4.5z M174.1,9.2c0,0,0,0.2,0.1,0.2c0.1,0,0.5,0.1,1.3,0.1c1.2,0,1.7,0,2.1-0.5
	c0.4-0.5,0.8-1.2,0.8-2.1c0.1-2.1-1.7-3.7-4.1-3.1c-0.2,0-0.3,0.1-0.3,0.2L174.1,9.2L174.1,9.2z M174.1,11.4c0,1.5,0,3.9,0,4.2
	c0.1,1,0.1,1.2,0.7,1.5c0.6,0.3,1.5,0.3,1.9,0.3c4.6,0.3,3.9-7.1-0.8-7.2c-0.3,0-1.4,0-1.7,0c-0.1,0-0.1,0-0.1,0.1L174.1,11.4
	L174.1,11.4z M186.4,4.5c0-0.7-0.1-1.1-1.3-1.1c-0.6,0-0.5-0.4-0.1-0.4c1,0,2.4,0,2.5,0c3.2,0.1,8-0.9,11.4,2.2
	c2.9,2.5,2.6,8.1-0.2,10.5c-0.9,0.9-2.8,2.3-6.5,2.3c-2.4,0-4.4-0.4-6.9-0.2c-0.4,0-0.5-0.3,0-0.3c0.9,0,1-0.5,1-1.1
	C186.6,13.5,186.4,7.5,186.4,4.5z M188.4,15.2c0,0.5,0.1,1.2,0.2,1.4c0.3,0.4,1,0.8,3.5,0.8c2,0,3.7-0.6,5-1.8
	c2.5-2.4,1.9-7.9-0.3-9.7c-2.3-2.2-5.4-2.5-8.2-2.1c-0.2,0.1-0.3,0.2-0.3,0.4C188.3,7,188.3,12.5,188.4,15.2z M491.5,4.5
	c0-0.7-0.1-1.1-1.3-1.1c-0.6,0-0.5-0.4-0.1-0.4c1,0,2.4,0,2.5,0c3.2,0.1,8-0.9,11.4,2.2c2.9,2.5,2.6,8.1-0.2,10.5
	c-0.9,0.9-2.8,2.3-6.5,2.3c-2.4,0-4.4-0.4-6.9-0.2c-0.4,0-0.5-0.3,0-0.3c0.9,0,1-0.5,1-1.1C491.7,13.5,491.5,7.5,491.5,4.5z
	 M493.4,15.2c0,0.5,0.1,1.2,0.2,1.4c0.3,0.4,1,0.8,3.5,0.8c2,0,3.7-0.6,5-1.8c2.5-2.4,1.9-7.9-0.3-9.7c-2.3-2.2-5.4-2.5-8.2-2.1
	c-0.2,0.1-0.3,0.2-0.3,0.4C493.4,7,493.4,12.5,493.4,15.2z M204.5,16.7c-0.9-0.9,0.9-2.1,1.6-1.1c1.2,1.6-1.3,4.8-1.7,4.6
	C203.7,19.8,206,18.3,204.5,16.7z M231.9,1c0.4-1.2,0.6-1.2,1-0.1c0.3,0.9,4.2,10.5,5.7,14c0.9,2.1,1.6,2.5,2.1,2.6
	c0.4,0.1,0.7,0.1,1,0.1c0.1,0,0.2,0,0.2,0.1s-0.2,0.2-0.5,0.2c-0.3,0-2.1,0-3.8,0c-0.5,0-0.7,0-0.7-0.2c0-0.1,0.1-0.1,0.2-0.2
	c0.1,0,0.3-0.3,0.1-0.6l-2.3-5.7c0,0-0.1-0.1-0.2-0.1h-5.2c0,0-0.2,0.1-0.2,0.2l-1.5,4.1c-0.4,1.1-0.8,2.3,0.8,2.2
	c0,0,0.2,0,0.2,0.1c0,0.1-0.1,0.2-0.3,0.2c-0.6,0-1.6-0.1-1.9-0.1c-0.3,0-1.6,0.1-2.7,0.1c-0.3,0-0.5,0-0.5-0.2
	c0-0.2,0.7-0.1,0.9-0.2c1.1-0.1,1.6-1,2-2.2L231.9,1z M234.5,10.3c0,0,0.1-0.1,0.1-0.2l-2.3-6.3c-0.1-0.4-0.2-0.4-0.3,0l-2.1,6.3
	c0,0,0,0.2,0.1,0.2H234.5z M269.9,4.5c0-0.7-0.1-1.1-1.4-1.1c-0.4,0-0.5-0.3,0.1-0.3c2.6,0,8,0.1,8.5-0.1c0.1,0,0.2-0.1,0.3-0.1
	c0.3,0.1-0.1,0.5-0.1,1.1c0,0.2-0.1,1.1-0.1,1.3c-0.1,0.3-0.4,0.3-0.4-0.1c0-0.9-0.1-1.1-1.7-1.3c-0.4,0-2.8-0.1-3.1-0.1
	c-0.1,0-0.1,0.1-0.1,0.2v5.4c0,0,0,0.2,0.1,0.2c0.3,0,3.1,0,3.6,0c0.5,0,0.9,0,1.2-0.4c0.2-0.2,0.4-0.1,0.3,0.2
	c-0.2,0.6-0.3,2.1-0.3,2.2c0,0.5-0.3,0.5-0.3,0.2c0-0.9-0.4-1.1-1.2-1.2c-0.6-0.1-2.9-0.1-3.2-0.1c-0.1,0-0.1,0.1-0.1,0.1
	c0,1.3,0,3.9,0,5c0,1.3,0.5,1.6,2.6,1.6c0.6,0,1.6,0,2.1-0.2c0.6-0.2,0.9-0.6,1-1.4c0-0.4,0.4-0.4,0.3,0c0,0.1-0.2,1.5-0.3,1.9
	c-0.1,0.5-0.4,0.5-1.2,0.5c-1.6,0-5.8-0.2-7.4-0.1c-0.7,0.1-0.7-0.4-0.2-0.4c0.9,0,1-0.4,1-1.1C269.9,13.5,269.9,7.5,269.9,4.5z
	 M426.6,4.5c0-0.7-0.1-1.1-1.4-1.1c-0.4,0-0.5-0.3,0.1-0.3c2.6,0,8,0.1,8.5-0.1c0.1,0,0.2-0.1,0.3-0.1c0.3,0.1-0.1,0.5-0.1,1.1
	c0,0.2-0.1,1.1-0.1,1.3c-0.1,0.3-0.4,0.3-0.4-0.1c0-0.9-0.1-1.1-1.7-1.3c-0.4,0-2.8-0.1-3.1-0.1c-0.1,0-0.1,0.1-0.1,0.2v5.4
	c0,0,0,0.2,0.1,0.2c0.3,0,3.1,0,3.6,0c0.5,0,0.9,0,1.2-0.4c0.2-0.2,0.4-0.1,0.3,0.2c-0.2,0.6-0.3,2.1-0.3,2.2c0,0.5-0.3,0.5-0.3,0.2
	c0-0.9-0.4-1.1-1.2-1.2c-0.6-0.1-2.9-0.1-3.2-0.1c-0.1,0-0.1,0.1-0.1,0.1c0,1.3,0,3.9,0,5c0,1.3,0.5,1.6,2.6,1.6
	c0.6,0,1.6,0,2.1-0.2c0.6-0.2,0.9-0.6,1-1.4c0-0.4,0.4-0.4,0.3,0c0,0.1-0.2,1.5-0.3,1.9c-0.1,0.5-0.4,0.5-1.2,0.5
	c-1.6,0-5.8-0.2-7.4-0.1c-0.7,0.1-0.7-0.4-0.2-0.4c0.9,0,1-0.4,1-1.1C426.6,13.5,426.6,7.5,426.6,4.5z M458.4,4.5
	c0-0.7-0.1-1.1-1.4-1.1c-0.4,0-0.5-0.3,0.1-0.3c2.6,0,8,0.1,8.5-0.1c0.1,0,0.2-0.1,0.3-0.1c0.3,0.1-0.1,0.5-0.1,1.1
	c0,0.2-0.1,1.1-0.1,1.3c-0.1,0.3-0.4,0.3-0.4-0.1c0-0.9-0.1-1.1-1.7-1.3c-0.4,0-2.8-0.1-3.1-0.1c-0.1,0-0.1,0.1-0.1,0.2v5.4
	c0,0,0,0.2,0.1,0.2c0.3,0,3.1,0,3.6,0c0.5,0,0.9,0,1.2-0.4c0.2-0.2,0.4-0.1,0.3,0.2c-0.2,0.6-0.3,2.1-0.3,2.2c0,0.5-0.3,0.5-0.3,0.2
	c0-0.9-0.4-1.1-1.2-1.2c-0.6-0.1-2.9-0.1-3.2-0.1c-0.1,0-0.1,0.1-0.1,0.1c0,1.3,0,3.9,0,5c0,1.3,0.5,1.6,2.6,1.6
	c0.6,0,1.6,0,2.1-0.2c0.6-0.2,0.9-0.6,1-1.4c0-0.4,0.4-0.4,0.3,0c0,0.1-0.2,1.5-0.3,1.9c-0.1,0.5-0.4,0.5-1.2,0.5
	c-1.6,0-5.8-0.2-7.4-0.1c-0.7,0.1-0.7-0.4-0.2-0.4c0.9,0,1-0.4,1-1.1C458.4,13.5,458.4,7.5,458.4,4.5z M308.3,16.5
	c-0.1,1.1,1,1.1,1.8,1.1c0.3,0.1,0.2,0.4-0.2,0.3c-2.7-0.1-2.5-0.1-4.9,0c-0.9-0.2,0.1-0.4,0.5-0.4c0.5-0.1,0.6-0.5,0.6-1.1L306.2,4
	h-3.1c-1.7,0.1-2.1,0.3-2.5,1.3c0,0.2-0.3,0.3-0.3,0c0-0.2,0.4-2,0.5-2.1c0.2-0.7,0.3-0.2,1.1-0.1c2.1,0.2,9.3,0.2,11.3,0
	c0.4,0,0.7-0.3,0.7,0.1c0,0.6-0.1,2-0.1,2.3c0,0.3-0.4,0.4-0.4-0.1c0-0.8-0.4-1.2-2.6-1.3h-2.7L308.3,16.5z M317.2,4.5
	c0-0.6-0.2-1-0.9-1.1c-0.4,0.1-1.5-0.2-0.6-0.4c2.3,0,2.6,0,4.6,0c0.8,0.1,0.1,0.4-0.4,0.4c-0.6,0.1-0.7,0.4-0.7,1.1
	c-0.1,1.7,0,5.2,0,7c0,2.9,0.7,4,1.6,4.8c1.8,1.6,5,1.3,6.5-0.3c1.5-1.5,1.2-5,1.2-7.3c0-3.1,0-3.6,0-4.2c0-0.6-0.1-1-0.9-1.1
	c-0.4,0.1-1.5-0.2-0.6-0.4c2.2,0,2.1,0,4.1,0c0.8,0.1,0.1,0.4-0.3,0.4c-0.6,0.1-0.7,0.4-0.8,1.1c0-0.1,0,4.8,0,6.4
	c0.2,4.5-2,7.5-6.4,7.4c-3.2,0.1-6.4-1.7-6.3-6.6C317.3,9.9,317.3,6.3,317.2,4.5z M336.1,4.5c0-0.7-0.2-1-0.9-1.1
	c-0.4,0.1-1.5-0.2-0.6-0.4c3.3,0.3,6.2-0.7,9.2,0.9c2.4,1.7,1.8,5-1.1,7.2c2.4,2.6,4.2,5.9,6.9,6.5c0.7,0,1.5,0.3,0.5,0.4
	c-3.3,0.2-4.4-0.5-6.5-3.3c-0.9-1.1-1.8-2.4-2.3-2.9c-0.1-0.1-0.1-0.1-0.3-0.1h-2.7c0,0-0.1,0-0.1,0.1c0,1.8,0,3.9,0.1,4.7
	c-0.1,1,1.1,1.1,1.8,1.1c0.3,0.1,0.2,0.4-0.2,0.3c-2.7,0-2.4-0.1-4.7,0c-0.9-0.2,0.1-0.4,0.5-0.4c0.4-0.1,0.4-0.5,0.6-1L336.1,4.5z
	 M337.9,10.4c0,0,0,0.2,0.1,0.3c2.3,0.7,5.2,0.7,5.2-3.1c0-3.2-2.7-4.4-5.2-3.7c-0.1,0-0.2,0.1-0.2,0.2L337.9,10.4L337.9,10.4z
	 M354.4,12.9c0-1.1-0.1-1.5-0.4-2c-0.2-0.3-3.1-4.7-3.8-5.7c-0.8-1-1.4-1.6-2.3-1.7c-0.3,0-0.3-0.4,0.1-0.3c1.7,0,2.1,0,4,0
	c0.5,0,0.3,0.2-0.1,0.3c-0.5,0.2-0.2,0.7,0,1.1l3.9,6.2c0.4-0.8,3.1-5.1,3.5-5.7c0.3-0.6,0.6-1.3,0-1.5c-0.4-0.1-0.5-0.4,0-0.4
	c1.4,0,2.1,0,3.6,0c0.4,0,0.4,0.3,0,0.3c-0.9,0-1.4,0.4-2.1,1.3c-0.7,0.8-3.7,5.4-4.2,6.3c-0.3,0.7-0.3,1.3-0.3,2
	c0,1.3,0,2.1,0.1,3.7c-0.1,1.1,1.1,1.1,1.8,1.1c0.3,0.1,0.2,0.4-0.2,0.3c-2.6,0-2.5-0.1-4.8,0c-0.9-0.2,0.1-0.4,0.5-0.4
	c0.5-0.1,0.6-0.5,0.6-1L354.4,12.9z M392.7,4.5c0-0.7-0.2-1-0.9-1.1c-0.4,0.1-1.5-0.2-0.6-0.4c2.8,0,6.3,0.2,8.9-0.1
	c0.7-0.3,0.3,0.2,0.2,1c0,0.2,0,1.1-0.1,1.3c0,0.3-0.4,0.3-0.4,0c-0.1-0.8-0.4-1.3-1.7-1.3h-3.5c0,0-0.2,0-0.2,0.2v5.4
	c0,0,0,0.2,0.1,0.2c0.7,0,3.2,0,3.9,0c0.5,0,0.8-0.1,1-0.4c0.1-0.2,0.4-0.3,0.4,0c-0.1,0.4-0.3,1.9-0.3,2.4c0.1,0.3-0.3,0.6-0.3,0.2
	c0-0.7-0.2-1.2-1.2-1.4c-0.5-0.1-3.1,0-3.4,0c-0.1,0-0.1,0-0.1,0.2c0,1.7,0,5,0.1,5.9c0,1.1,1,1.1,1.7,1.1c0.3,0,0.2,0.4-0.2,0.3
	c-2.6,0-2.5-0.1-4.8,0c-0.4,0-0.5-0.3-0.2-0.3c0.8,0,1.1-0.2,1.2-1.1L392.7,4.5z M413.6,4.5c0-0.7-0.2-1-0.9-1.1
	c-0.4,0.1-1.5-0.2-0.6-0.4c2.4,0,2.5,0,4.8,0c0.9,0.2-0.1,0.4-0.5,0.3c-0.6,0.1-0.8,0.4-0.8,1.1c0,3.1-0.3,11.4,0.3,12.2
	c0.4,0.5,4.7,0.8,5.4-0.1c0.3-0.3,0.5-0.7,0.5-1.1c0-0.3,0.4-0.4,0.3,0c0,0.2-0.1,1.4-0.3,2c-0.1,0.5-0.2,0.5-1.4,0.5
	c-3.2,0-5.3-0.2-8.1-0.1c-0.9-0.2,0.2-0.4,0.5-0.4c0.4-0.1,0.5-0.5,0.6-1C413.8,13.6,413.7,7.5,413.6,4.5z"/>
</svg>
<div class="t3" data-aos="zoom-in" data-aos-delay="600">巨擎新案</div>
<a class="contact-btn" v-scroll-to="{ element: `#contact`, offset: offset }">
<div class="t4" data-aos="zoom-in" data-aos-delay="800">立即預約</div>
</a>
</div>
</div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.section1 {
  width: 100%;
  height: 100vh;
  min-height: size(1080);
  max-height: size(1080);
 // overflow: hidden;
  position: relative;
  // background: url("./s1/bg.jpg") center;
  // background-size: 100% auto;
}
.t0{
  position: absolute;
  width: 100%;height:auto;
  top:0;
  left:0;object-fit: cover;
   opacity: .5;
  }
  .img{position: absolute;top: 0;left: 0;width: 100%;height: 100%;transform: scale(1.5);}
.img1{position: absolute;left: 0;bottom:calc(50% + (0 - 1080 * 0.5) * 100vw / 1920);width: size(459);}
@keyframes light1{
    to{
      transform:translateX(100%);
    }
}
.img11{position: absolute;left: 0;bottom:calc(50% + (0 - 1080 * 0.5) * 100vw / 1920);width: size(459);height:size(795);
  clip-path: polygon(0 0,100% 100%,81% 100%,0 19.3%,
  0 24%,76% 100%,75% 100%,0% 25%,
  0% 29%,71% 100%,70% 100%,0% 30%,
  0% 34%,66% 100%,65% 100%,0% 35%,
  0% 39%,61% 100%,60% 100%,0% 40%);
  &::before{content:"";position: absolute;top:0;left:0;width: 100%;height: 100%;background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
      transform:translateX(-100%);
      animation: light1 3s infinite;}
}
.img22{position: absolute;right: size(-543);
  bottom:calc(50% + (0 - 1080 * 0.5) * 100vw / 1920);width: size(1160);height:size(1080);
  clip-path: polygon(46.15% 0,72.1% 48.1%,71.7% 48.9%,45.4% 0%,
  32.3% 0%,65.1% 61.1%,64.75% 61.75%,85% 100%,74% 100%,20.2% 0%);
  &::before{
    content:"";position: absolute;top:0;left:0;width: 100%;height: 100%;background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
      transform:translateX(-100%);
      animation: light1 3s -2.5s infinite;}
}
.img23{position: absolute;right: size(-543);
  bottom:calc(50% + (0 - 1080 * 0.5) * 100vw / 1920);width: size(1160);height:size(1080);
  clip-path: polygon(2.2% 100%,56% 0,57% 0,3.3% 100%,
  4.7% 100%,58.2% 0,59.3% 0,5.8% 100%,
  7.15% 100%,60.7% 0,61.8% 0,8.25% 100%,
  9.6% 100%,63.2% 0,64.3% 0,10.7% 100%,
  12.0% 100%,65.7% 0,66.8% 0,13.1% 100%,
  14.4% 100%,68.2% 0,69.3% 0,15.5% 100%,
  16.8% 100%,70.7% 0,71.8% 0,17.9% 100%,
  19.2% 100%,73.2% 0,74.3% 0,20.3% 100%,
  21.6% 100%,75.5% 0,76.6% 0,22.55% 100%,
  24.0% 100%,77.8% 0,78.9% 0,25.1% 100%,
  26.5% 100%,80.0% 0,81.1% 0,27.6% 100%,
  28.9% 100%,82.4% 0,83.5% 0,30.0% 100%,
  31.3% 100%,84.8% 0,85.9% 0,32.4% 100%,
  33.7% 100%,87.2% 0,88.3% 0,34.8% 100%,
  36.2% 100%,89.6% 0,90.7% 0,37.3% 100%,
  38.6% 100%,92.0% 0,93.1% 0,39.7% 100%,
  41.0% 100%,94.4% 0,95.5% 0,42.1% 100%,
  43.2% 100%,97.1% 0,98.0% 0,44.3% 100%
  );
  &::before{
    content:"";position: absolute;top:0;left:0;width: 100%;height: 100%;background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
      transform:translateX(-100%);opacity: .5;
      animation: light1 3s -2s infinite;}
}
.img2{position: absolute;right: size(-543);
  bottom:calc(50% + (0 - 1080 * 0.5) * 100vw / 1920);width: size(1160);}
.img3{position: absolute;left: 0;top:calc(50% + (1080 - 1080 * 0.5) * 100vw / 1920);width: size(512);}

.txt{
  position: relative;
  // top:calc(50% + (253 - 540) * 100vw / 1920);
 //  left:size(-260);
  top:calc(50% + (240 - 1080 * 0.5) * 100vw / 1920);
  font-size: size(35);
  line-height: 1.5;
  color: #fff;
  text-align: center;
  width:size(1500);
    letter-spacing:0em;
    padding: 0;
  font-weight: 700;
  margin: auto;

  //filter: drop-shadow(0 0 7px #eff);
.logo{
  width:size(747);
  margin: 0 auto 1.2em;
  fill:url(#SVGID_1_);
  filter: drop-shadow(0 0 size(7) #00606e);
  .an1{transform: translateX(0%);}
}

  
.t1{
    display: block;
  font-size: 1.14em;
  font-weight: 700;
  margin: 0 auto -.3em auto;
    letter-spacing:0.02em;
    text-shadow: 0 0.05em 0.27em rgba(19, 73, 101, 0.8);
    }
  .t2{
  fill:#134965;
  width:size(506);
    }
  .t3{
  font-size: 1.13em;
  margin: .7em auto 1.2em auto;
    text-shadow: 0 0.05em 0.27em rgba(19, 73, 101, 0.8);
  &::after,
  &::before{
    content: "";
    width: 6.6em;
    height: 2px;
    background: #F4DB8C;
    display:inline-block;
    vertical-align: middle;
    margin: 0 .6em .1em;
  }
    }
  .contact-btn{
   width: 10.22em;
	}	
  .t4{
width: 10.22em;
margin: auto;
background: #134965;
border: 2px solid #F4DB8C;
border-radius: 0.57em;
padding:.5em 0;
  cursor: pointer;
  overflow: hidden;
  &::before {
    content: "";
    display: block;
    background: hsla(0,0%,100%,.4);
    position: absolute;
    left: -15%;
    top: 0;
    width: 120%;
    height:100%;
    transform: skewX(-30deg) translateX(-100%);
    transition: transform .4s;
    transform-origin: 0 0;
  }
  &:hover:before {
    transform: skewX(-30deg) translateX(120%);
  }
    }

}
/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section1 {
    min-height: sizem(667);
    max-height: sizem(812);
    height:100vh;
  }
  .img1{bottom:auto;top: sizem(-345);width:auto;height: sizem(345);
    transform: rotate(90deg);transform-origin: 0 100%;}
    .img11{bottom:auto;top: sizem(-345);width:sizem(199.19);height: sizem(345);
      transform: rotate(90deg);transform-origin: 0 100%;

    }

.img2{left: 0;bottom: sizem(214);width:auto;height: sizem(375);
    transform: rotate(90deg);transform-origin: 0 100%;}
.img22,
.img23{
  left: 0;bottom: sizem(214);width:sizem(402.77);height: sizem(375);
  transform: rotate(90deg);transform-origin: 0 100%;
}
    .box{position: absolute;
      width: 100%;height:sizem(200);bottom:sizem(-100);background: linear-gradient(to bottom, #A4D2D900 0%,#A4D2D9 50%,#A4D2D900 100%);display: block;}

.txt{
  font-size: sizem(25);
  top:calc(50% + (174 - 667 * .5) * 100vw / 375);
  width:100%;
.logo{
  width:sizem(286);
  margin: 0 auto 0.2em;
  filter: drop-shadow(0 0 sizem(7) #00606e);
}
.t1{
  font-size: 1em;
  line-height: 1.48;
  margin: 0 auto -.5em auto;
  span{display: block;font-size:0.95em;}
    }
  .t2{
  width:sizem(238);
    }
  .t3{
  font-size: 1em;
  margin: .2em auto 1.2em auto;
  &::after,
  &::before{
    content: "";
    width: 2.1em;
    margin: 0 .4em .1em;
  }

}
}
}
</style>
<script>
// @ is an alias to /src
import info from '@/info'
import { isPC, isMobile, isTablet } from '@/utils'

export default {
  name: 'section1',
  components: {},

  props: ['title', 'cls'],

  data() {
    return {
      info,
      isPC,
      isMobile,
      isTablet,
    }
  },

  methods: {
    init() {},},

  mounted() {},

  created() {},

  computed: {},
}
</script>
